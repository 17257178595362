import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert } from "react-bootstrap";

import { Panel, PanelHeader, PanelBody, PanelFooter } from "./index";

const ServerError = ({ errors, inline }) => {
  if (errors === null || typeof errors === "undefined") {
    return null;
  }

  let errorHeadline = "";
  let errorMessages = [];
  console.log("Errors is", JSON.stringify(errors, null, 4));
  if (errors.graphQLErrors && errors.graphQLErrors.length > 0) {
    //Graphql Error
    errorHeadline = "Input caused a problem. (Local)";
    errors.graphQLErrors.map(({ message }) => errorMessages.push(message));
  } else if (errors.networkError && errors.networkError.message) {
    errorHeadline = "Network problem encountered.";
    errorMessages.push(errors.networkError.message);
  } else if (errors.message) {
    errorHeadline = "Server Error";
    errorMessages.push(errors.message);
  } else {
    //Active Record?

    let msgs = [];

    // console.log(errors)
    if (errors.fullMessages) {
      const fullMessages = JSON.parse(errors.fullMessages);
      Object.keys(fullMessages).map(key => {
        msgs = msgs.concat(fullMessages[key]);
      });
    } else {
      Object.keys(errors).map(key => {
        msgs = msgs.concat(errors[key]);
      });
    }
    errorHeadline = "Input caused a problem.";
    msgs.map(message => errorMessages.push(message));
  }
  if (inline) {
    return (
      <div className="text-danger">
        <b>
          <FontAwesomeIcon
            icon="exclamation-triangle"
            className="text-danger"
          />{" "}
          {errorHeadline}
        </b>{" "}
        {errorMessages.map(message => (
          <span key={message}>{message}</span>
        ))}
      </div>
    );
  } else {
    return (
      <Panel bsStyle="danger" style={{ margin: "3rem" }}>
        <PanelHeader bsStyle="danger">
          <FontAwesomeIcon icon="exclamation-triangle" /> {errorHeadline}
        </PanelHeader>
        <PanelBody>
          <ul className="fa-ul">
            {errorMessages.map(message => (
              <li key={message}>
                <p>
                  <FontAwesomeIcon icon="exclamation-triangle" listItem />
                  {message}{" "}
                </p>
              </li>
            ))}
          </ul>
        </PanelBody>
      </Panel>
    );
  }
};

export default ServerError;
