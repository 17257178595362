import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment"; // eslint-disable-line
// Based on code from https://github.com/erikras/redux-form/issues/1860#issuecomment-315188615
import {
  FormGroup,
  Col,
  ControlLabel,
  Grid,
  Row,
  Label,
  InputGroup
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Required, Button } from "components/shared";

require("components/styles/css/react-datepicker-cssmodules.global.css");

export default class FieldDateRangePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedInput: null
    };
  }
  handleStartDateChange = date => {
    this.props.input.onChange({
      startDate: date,
      endDate: this.props.input.value.endDate
    });
  };
  handleEndDateChange = date => {
    this.props.input.onChange({
      startDate: this.props.input.value.startDate,
      endDate: date
    });
  };
  onStartDateKeyDown = event => {
    const { keyCode } = event;
    switch (keyCode) {
      case 9: // TAB
      case 13: // ENTER
        event.stopPropagation();

        this.handleStartDateChange(moment(event.target.value, "MM/DD/YYYY"));
        // this.updateSearch(event);
        break;
      case 27: // escape
        event.preventDefault();
        event.stopPropagation();
        this.handleStartDateChange(null);
        break;
    }
  };

  onEndDateKeyDown = event => {
    const { keyCode } = event;
    switch (keyCode) {
      case 9: // TAB
      case 13: // ENTER
        event.stopPropagation();

        this.handleEndDateChange(moment(event.target.value, "MM/DD/YYYY"));
        // this.updateSearch(event);
        break;
      case 27: // escape
        event.preventDefault();
        event.stopPropagation();
        this.handleEndDateChange(null);
        break;
    }
  };

  render() {
    // console.log("Field props?", this.props);
    const {
      input,
      hint,
      meta,
      label,
      activeFieldList,
      disabledFieldList,
      required
    } = this.props;

    if (activeFieldList) {
      if (!activeFieldList.includes(input.name)) {
        return null;
      }
    }
    if (disabledFieldList) {
      if (disabledFieldList.includes(input.name)) {
        return null;
      }
    }

    const startDate = input.value.startDate ? input.value.startDate : null;
    const endDate = input.value.endDate ? input.value.endDate : null;
    if (label) {
      return (
        <FormGroup controlId={`form-${input.name}`}>
          <Col componentClass={ControlLabel} sm={3}>
            {label}{" "}
            {required && (
              <span>
                <Required isRequired />
              </span>
            )}
          </Col>
          <Col sm={9} style={{ marginBottom: "6px" }}>
            <Grid fluid>
              <Row>
                <Col md={3} style={{ zIndex: 200 }}>
                  Starts
                  <DatePicker
                    onKeyDown={this.onStartDateKeyDown}
                    selected={startDate}
                    openToDate={startDate || moment()}
                    onChange={this.handleStartDateChange}
                    dateFormat="MM/DD/YYYY"
                    className="form-control"
                    style={{ zIndex: 200 }}
                  />{" "}
                </Col>
                <Col md={3} style={{ zIndex: 200 }}>
                  Ends
                  <DatePicker
                    onKeyDown={this.onEndDateKeyDown}
                    selected={endDate}
                    openToDate={endDate || moment()}
                    onChange={this.handleEndDateChange}
                    dateFormat="MM/DD/YYYY"
                    className="form-control"
                    style={{ zIndex: 200 }}
                  />
                  {hint && <span className="text-info">{hint}</span>}
                  {meta.touched &&
                    meta.error && (
                      <span className="text-danger">
                        <FontAwesomeIcon icon="exclamation-triangle" />{" "}
                        {meta.error}
                      </span>
                    )}
                </Col>
              </Row>
            </Grid>
          </Col>
        </FormGroup>
      );
    }
    return (
      <FormGroup controlId={`form-${input.name}`}>
        {hint && <span className="text-info">{hint}</span>}
        {meta.touched &&
          meta.error && (
            <span className="text-danger">
              <FontAwesomeIcon icon="exclamation-triangle" /> {meta.error}
            </span>
          )}
      </FormGroup>
    );
  }
}
