import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  AddressBlock,
  Avatar,
  Button,
  Card,
  CardBody,
  CardSubTitle,
  CardText,
  CardTitle,
  LoadingIcon,
  Page
} from "components/shared";

const PersonalCard = ({
  bgStyle,
  email,
  name,
  preferredName,
  jobTitle,
  companyName,
  locationName,
  primaryPhoneNumber,
  primaryEmail,
  button_icon,
  button_text,
  button_to,
  bornOn,
  personalPhotoUrl
}) => {
  const bgImg = (
    <div className="image">
      <img
        alt="background"
        src="https://images.unsplash.com/photo-1493237643690-e6e27427ff57?dpr=1&auto=compress,format&fit=crop&w=1200&h=&q=75&cs=tinysrgb&crop="
      />
    </div>
  );

  return (
    <div className="card card-user" style={{ minHeight: "500px" }}>
      {bgImg}
      <div className="content">
        <div className="author">
          <Avatar
            className="avatar border-gray"
            size={100}
            personalPhotoUrl={personalPhotoUrl}
            email={email || "demo@brokerageengine.com"}
          />

          <h3 className="title">
            {name}
            <br />
            <small>
              <FontAwesomeIcon icon="quote-left" /> {preferredName}{" "}
              <FontAwesomeIcon icon="quote-right" />
            </small>
          </h3>
        </div>
        <div className="text-center">
          <div style={{ margin: "0.8rem" }}>
            <b>{companyName}</b>
          </div>
          <div style={{ margin: "0.8rem" }}>
            <b>{locationName}</b>
          </div>
          <div style={{ margin: "0.8rem" }}>{jobTitle}</div>

          {primaryPhoneNumber}

          {primaryEmail}
          <div style={{ margin: "0.8rem" }}>
            <b>Commmission Anniversary </b>
            <FontAwesomeIcon icon={["far", "repeat"]} />{" "}
            {commissionAnniversaryDate || "--"}
          </div>
          <div style={{ margin: "0.8rem" }}>
            <b>Birthday</b> <FontAwesomeIcon icon={["far", "birthday-cake"]} />{" "}
            {bornOn ? bornOn.date : "--"}
          </div>
        </div>
      </div>
      {button_to && (
        <div className="text-center" style={{ marginBottom: "1rem" }}>
          <Button to={button_to} icon={button_icon} title={button_text} />
        </div>
      )}
    </div>
  );
};
// LoadingMessage.propTypes = {
//  isLoading: PropTypes.bool.isRequired
// };
export default PersonalCard;
