import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTitle from "./MetaTitle";
import LoadingMessage from "./LoadingMessage";

import Page from "./Page";

const LoadingScreen = ({ isLoading, pageTitle }) => {
  if (isLoading) {
    return (
      <div className="content">
        <MetaTitle pageTitle={pageTitle || "...Loading"} />
        <div className="container-fluid">
          <LoadingMessage isLoading={isLoading} />
        </div>
      </div>
    );
  }
  return null;
};
LoadingScreen.propTypes = {
  isLoading: PropTypes.bool
};
LoadingScreen.defaultProps = {
  isLoading: true
};
export default LoadingScreen;
