import React from "react";
import AppConfig from "config/AppConfig";

const SidebarBrandingBlock = ({ accountName, logoIcon }) => (
  <div className="branding-block">
    <div className="logo">
      <a href={AppConfig.appUrl} className="simple-text logo-mini">
        <div className="logo-img">
          <img src={logoIcon} alt="logo_image" />
        </div>
      </a>
      <a
        href={AppConfig.appUrl}
        className="simple-text logo-normal"
        style={{ fontSize: "1.6rem", marginTop: ".5rem" }}
      >
        Brokerage Engine
      </a>
    </div>

    {accountName != "Kuper Sotheby's International Realty" &&
      accountName != "American ERA" &&
      accountName != "Atlanta Fine Homes Sotheby's International Realty" &&
      accountName != "Realogics" &&
      accountName != "Scenic Sotheby's International Realty" &&
      accountName != "Dielmann Sotheby's International Realty" && (
        <div className="text-center logo"> {accountName} </div>
      )}
  </div>
);
SidebarBrandingBlock.propTypes = {};
SidebarBrandingBlock.defaults = {};
export default SidebarBrandingBlock;
