import config from "config/reactql_config";
import { reducer as uiReducer } from "redux-ui";
import { reducer as formReducer } from "redux-form";
import settings from "./settings";
import pagination_tracker from "./pagination_tracker";
import breadcrumb from "./breadcrumb";

config.addReducer("form", formReducer);
config.addReducer("ui", uiReducer);
config.addReducer("settings", settings);
config.addReducer("pagination_tracker", pagination_tracker);
config.addReducer("breadcrumb", breadcrumb);
