import Immutable from "seamless-immutable";
import AT from "../constants/ActionTypes";

const initialState = Immutable.from({
  cursors: {}
});
const reducer = {};

export default function settings(
  state = initialState,
  action = { type: null }
) {
  return action && action.type && reducer[action.type]
    ? reducer[action.type](state, action)
    : state;
}

reducer[AT.LOG_OUT_USER] = (state, _) => {
  return state.merge(initialState);
};
reducer[AT.SET_CURSOR] = (state, action) => {
  return state.merge({ cursors: action.payload.cursor });
};
