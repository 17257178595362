import Immutable from "seamless-immutable";
import AT from "../constants/ActionTypes";

// Sample reducer, showing how you can 'listen' to the `INCREMENT_COUNTER`
// action, and update the counter state

// Note: There's no need to specify default state, because the kit's Redux
// init code wraps `undefined` state values in a `defaultReducer()` function,
// that captures Redux sentinel vals and responds back with a black object --
// so in our reducer functions, we can safely assume we're working with 'real'
// immutable state

// export default function reducer(state, action) {
//   if (action.type === "INCREMENT_COUNTER") {
//     // Where did `state.merge()` come from?  Our plain state object is automatically
//     // wrapped in a call to `seamless-immutable` in our reducer init code,
//     // so we can use its functions to return a guaranteed immutable version
//     return state.merge({
//       count: state.count + 1
//     });
//   }
//   return state;
// }

const initialState = Immutable.from({
  id_token: null,
  access_token: null,
  expires_at: null,
  login_pending: false
});
let reducer = {};

export default function settings(
  state = initialState,
  action = { type: null }
) {
  return action && action.type && reducer[action.type]
    ? reducer[action.type](state, action)
    : state;
}

reducer[AT.LOG_OUT_USER] = (state, _) => {
  return state.merge(initialState);
};
reducer[AT.LOG_IN_USER] = (state, action) => {
  //console.log("Logging in", action);

  //console.log(action.payload.id_token);
  return state.merge({
    login_pending: false,
    id_token: action.payload.id_token,
    access_token: action.payload.access_token,
    expires_at: action.payload.expires_at
  });
};
reducer[AT.PENDING_LOGIN_FOR_USER] = (state, action) => {
  //console.log("Logging in", action);

  //console.log(action.payload.id_token);
  return state.merge({
    login_pending: true
  });
};
