import React, { Component } from "react";
import PropTypes from "prop-types";

export default class Panel extends React.Component {
  render() {
    const { display } = this.props;
    if (display !== undefined && display !== true) {
      return null;
    }
    return (
      <div
        className={"panel panel-" + this.props.bsStyle}
        style={this.props.style}
      >
        {this.props.children}
      </div>
    );
  }
}

Panel.propTypes = {
  bsStyle: PropTypes.string
};
Panel.defaultProps = { bsStyle: "default" };
