import React from "react";

import { FormGroup, Col, FormControl, ControlLabel } from "react-bootstrap";
import Select from "react-select";

require("components/styles/css/react-select.global.css");
const FieldSearch = field => {
  // console.log("Field is:", field);
  if (field.activeFieldList) {
    if (!field.activeFieldList.includes(field.input.name)) {
      return null;
    }
  }
  if (field.disabledFieldList) {
    if (field.disabledFieldList.includes(field.input.name)) {
      return null;
    }
  }
  if (field.label) {
    return (
      <FormGroup controlId={`form-${field.input.name}`}>
        <Col componentClass={ControlLabel} sm={3}>
          {field.label}
        </Col>
        <Col sm={9} style={{ marginBottom: "6px" }}>
          <Select
            {...field.input}
            searchable={true}
            value={field.input.value}
            labelKey={field.labelKey ? field.labelKey : "name"}
            onChange={value => {
              return field.input.onChange(value.value);
            }}
            onBlur={() => field.input.onBlur(field.input.value)}
            options={field.options}
          />

          {field.hint && <span className="text-info">{field.hint}</span>}
          {field.meta.touched &&
            field.meta.error && (
              <span className="text-error">{field.meta.error}</span>
            )}
        </Col>
      </FormGroup>
    );
  } else {
    return (
      <FormGroup controlId={`form-${field.input.name}`}>
        <Select
          searchable={true}
          {...field.input}
          value={field.input.value}
          labelKey={field.labelKey ? field.labelKey : "name"}
          onChange={value => {
            return field.input.onChange(value.value);
          }}
          onBlur={() => field.input.onBlur(field.input.value)}
          options={field.options}
        />
        {field.hint && <span className="text-info">{field.hint}</span>}
        {field.meta.touched &&
          field.meta.error && (
            <span className="text-error">{field.meta.error}</span>
          )}
      </FormGroup>
    );
  }
};

export default FieldSearch;
