import React from "react";

import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "components/shared";
import BreadcrumbActions from "actions/BreadcrumbActions";
import getBreadcrumbs from "selectors/getBreadcrumbs";

const mapStateToProps = state => ({
  breadcrumbs: getBreadcrumbs(state)
});

function icon(typeName) {
  if (typeName === "BrokerageTransaction") {
    return "exchange";
  } else if (typeName === "ReportResult") {
    return "chart-line";
  } else if (typeName === "CommissionSchedule") {
    return "chart-pie";
  }

  return null;
}

class Breadcrumb extends React.Component {
  render() {
    const { breadcrumbs } = this.props;

    if (!breadcrumbs || breadcrumbs === {}) {
      return null;
    }
    return (
      <div>
        <hr
          style={{
            border: "0",
            borderTop: "1px solid rgba(255, 255, 255, 0.2)"
          }}
        />
        <div style={{ marginLeft: "16px" }}>
          <h5>
            <FontAwesomeIcon icon={["far", "list-alt"]} /> Recently Viewed
          </h5>
          <table>
            <tbody>
              {breadcrumbs.brokerageTransactions.map(
                ({ id, type, label, url }) => (
                  <tr key={id}>
                    <td>
                      <Button
                        icon={icon(type)}
                        link
                        to={url}
                        text={label}
                        styleOpts={{
                          maxWidth: "220px",
                          overflow: "hidden",
                          textOverflow: "ellipsis"
                        }}
                      />
                    </td>
                  </tr>
                )
              )}
              {breadcrumbs.commissionSchedules.map(
                ({ id, type, label, url }) => (
                  <tr key={id}>
                    <td>
                      <Button
                        icon={icon(type)}
                        link
                        to={url}
                        text={label}
                        styleOpts={{
                          maxWidth: "220px",
                          overflow: "hidden",
                          textOverflow: "ellipsis"
                        }}
                      />
                    </td>
                  </tr>
                )
              )}
              {breadcrumbs.reportResults.map(({ id, type, label, url }) => (
                <tr key={id}>
                  <td>
                    <Button
                      icon={icon(type)}
                      link
                      to={url}
                      text={label}
                      styleOpts={{
                        maxWidth: "220px",
                        overflow: "hidden",
                        textOverflow: "ellipsis"
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
export default connect(
  mapStateToProps,
  BreadcrumbActions
)(Breadcrumb);
