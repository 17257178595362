import React, { Component } from "react";
import { Form, Clearfix, ButtonToolbar, Alert } from "react-bootstrap";
import ui from "redux-ui";

import AppConfig from "config/AppConfig";

const productionMode = AppConfig.appEnv === "production";
import {
  Panel,
  PanelHeader,
  PanelBody,
  PanelFooter,
  ErrorFromForm
} from "components/shared";

import Button from "./Button";

@ui({
  state: {
    formError: null
  }
})
export default class SimpleForm extends Component {
  static required = value => (value ? undefined : "Please provide an answer.");
  static arrayRequired = value =>
    value && value.length > 0 ? undefined : "Please provide an answer.";
  static uploadComplete = value =>
    value ? undefined : "Please upload a file.";
  static moneyRequired = value =>
    value || value === "0" || value === 0
      ? undefined
      : "Please provide a value.";
  static boolRequired = value =>
    value === true || value === false ? undefined : "Please provide an answer.";
  static tagsRequired = value =>
    value && value.length > 0 ? undefined : "Please set at least one tag.";

  onSubmit = data => {
    const { history } = this.props;
    if (!productionMode) {
      console.log("Pre Sanitize Data ", data);
    }
    this.props
      .onSubmit(data)
      .then(({ resultData, errors, url, goBack }) => {
        if (!productionMode) {
          console.log("result", resultData);
          console.log("errors", errors);
          console.log("url", url);
          console.log("goBack", goBack);
        }
        if (errors && errors.hasErrors) {
          this.props.updateUI("formError", JSON.parse(errors.fullMessages));
        } else if (goBack) {
          history.goBack();
        } else if (url) {
          history.push(url);
        }
      })
      .catch(error => {
        this.props.updateUI("formError", error);
      });
  };

  render() {
    const {
      title,
      history,
      children,
      noCancel,
      handleSubmit,
      submitButtonText,
      allowPristineSubmission,
      pristine,
      ui: { formError },
      submitting
    } = this.props;

    let showCancel = true;
    if (noCancel) {
      showCancel = false;
    }
    return (
      <Form
        onSubmit={handleSubmit(this.onSubmit)}
        horizontal
        autoComplete="off"
      >
        <Panel>
          <PanelHeader>
            <h3>{title}</h3>
            {submitting && <Alert>Submitting form...</Alert>}
          </PanelHeader>

          <PanelBody>
            <ErrorFromForm errors={formError} />
            {children}
          </PanelBody>

          <PanelFooter>
            <ButtonToolbar className="pull-right">
              {showCancel && (
                <Button
                  link
                  color="danger"
                  text="Cancel"
                  goBack={history}
                  tabIndex={-1}
                />
              )}
              <Button
                type="submit"
                disabled={!allowPristineSubmission && pristine}
                name="save"
                text={submitButtonText || "Save"}
              />
            </ButtonToolbar>
            <Clearfix />
          </PanelFooter>
        </Panel>
      </Form>
    );
  }
}
