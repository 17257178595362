// https://gist.githubusercontent.com/samuel-holt/388acdecbff5e4cf50f35a6e3dcbb638/raw/7f5b0692340383fda4c9802537e77103c0b1e432/errorBoundary.jsx
import React from "react";
import Raven from "raven-js";
import ErrorPanel from "./ErrorPanel";

export default class ErrorBoundaryAppContainer extends React.Component {
  constructor(props) {
    super(props);
    const unlistenToHistory = props.history.listen((location, action) => {
      //    console.log(`The current URL is ${location.pathname}${location.search}${location.hash}`)
      //   console.log(`The last navigation action was ${action}`)
      if (action === "POP") {
        //   console.log("Changing the sate?")
        this.setState({ currentError: null, errorInfo: null });
      }
    });
    this.unlistenToHistory = unlistenToHistory;
  }
  state = {
    currentError: null,
    errorInfo: null
  };
  componentWillUnmount() {
    this.unlistenToHistory && this.unlistenToHistory();
  }
  componentDidCatch(error, info) {
    this.setState({
      currentError: error,
      errorInfo: info
    });
    Raven.captureException(error, { extra: info });
  }

  render() {
    if (!!this.state.currentError) {
      return (
        <div>
          <ErrorPanel
            {...this.props}
            currentError={this.state.currentError}
            errorInfo={this.state.errorInfo}
          />
          <div
            className="snap"
            onClick={() => Raven.lastEventId() && Raven.showReportDialog()}
          />
        </div>
      );
    }
    return this.props.children;
  }
}

// ErrorBoundaryAppContainer.defaultProps = {
//   debugMode: false
// };
