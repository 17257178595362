import React, { Component } from "react";

import { FieldSelect } from "components/shared";

export default class FieldYesNoSelect extends Component {
  static propTypes = {};
  static defaultProps = {};

  buildOptions() {
    return [
      { name: "No", value: false, disabled: false },
      { name: "Yes", value: true, disabled: false }
    ];
  }

  render() {
    const options = this.buildOptions();
    // console.log("Select", this.props);
    return <FieldSelect {...this.props} options={options} />;
  }
}
