// ----------------------
// IMPORTS

/* NPM */

// Apollo client library
import { ApolloClient } from "apollo-client";

/* ReactQL */

// Configuration
import config from "config/reactql_config";

// ----------------------
function getServerURL(
  host = process.env.HOST,
  port = process.env.PORT,
  allowSSL = true
) {
  // Check for SSL
  if (allowSSL && process.env.SSL_PORT) {
    const stub = `https://${host || process.env.HOST}`;

    // If we're on port 443, that's 'regular' SSL so no need to specify port
    if (process.env.SSL_PORT === "443") return stub;
    return `${stub}:${process.env.SSL_PORT}`;
  }

  // Plain HTTP
  const stub = `http://${host || process.env.HOST}`;

  // If we're on port 80, that's 'regular' HTTP so no need to specify port
  if (port === "80") return stub;
  return `${stub}:${port}`;
}

// Helper function to create a new Apollo client, by merging in
// passed options alongside any set by `config.setApolloOptions` and defaults
export function createClient(opt = {}) {
  return new ApolloClient(Object.assign({}, config.apolloClientOptions, opt));
}

// Creates a new browser client
export function browserClient() {
  // If we have an internal GraphQL server, we need to append it with a
  // call to `getServerURL()` to add the correct host (in dev + production)
  const uri = config.graphQLServer
    ? `${getServerURL()}${config.graphQLEndpoint}`
    : config.graphQLEndpoint;

  return createClient({});
}
