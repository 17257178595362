import React from "react";
import PropTypes from "prop-types";

import { LoadingIcon, Panel } from "components/shared";

const LoadingMessage = ({ isLoading }) => {
  if (isLoading) {
    return (
      <Panel style={{ margin: "0px", marginBottom: "12px", padding: "6px" }}>
        <LoadingIcon isLoading={isLoading} /> Getting fresh data.
      </Panel>
    );
  }
  return null;
};
LoadingMessage.propTypes = {
  isLoading: PropTypes.bool.isRequired
};
export default LoadingMessage;
