import React, { Component, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { Button, NavItem, Nav, Navbar } from "react-bootstrap";
import { connect } from "react-redux";

import AuthenticationActions from "actions/AuthenticationActions";
import isAuthenticated from "selectors/isAuthenticated";

const mapStateToProps = state => ({
  is_authenticated: isAuthenticated(state)
});

class HeaderLinks extends Component {
  static propTypes = {
    is_authenticated: PropTypes.bool.isRequired
  };
  static defaultProps = {
    is_authenticated: false
  };

  login = () =>
    new Promise((resolve, reject) => {
      this.props.serviceLogin(this.props.history, resolve, reject);
    });

  logout = () =>
    new Promise((resolve, reject) => {
      this.props.logOut();
    });
  render() {
    const {
      is_authenticated,
      data: { me }
    } = this.props;
    const notification = (
      <div>
        <FontAwesomeIcon icon={["far", "comment"]} />
        <b className="caret" />
        <span className="notification"> 5 </span>
        <p className="hidden-lg hidden-md">Notification</p>
      </div>
    );

    return (
      <div>
        <Nav pullRight>
          <NavItem
            style={{
              width: "25em",
              height: "2em",
              marginTop: "-8px",
              marginRight: "1em"
            }}
          />

          {!is_authenticated && (
            <NavItem>
              <Button bsStyle="link" onClick={this.login}>
                Sign In <FontAwesomeIcon icon={["far", "sign-in"]} />
              </Button>
            </NavItem>
          )}
          {is_authenticated && (
            <Fragment>
              <li role="presentation">
                <Link tabIndex="-1" to="/user_profile">
                  Your Profile <FontAwesomeIcon icon={["far", "user"]} />
                </Link>
              </li>

              <NavItem onClick={this.logout}>
                Sign Out <FontAwesomeIcon icon={["far", "sign-out"]} />
              </NavItem>
            </Fragment>
          )}
        </Nav>
      </div>
    );
  }
}
export default connect(
  mapStateToProps,
  AuthenticationActions
)(HeaderLinks);
