import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import classnames from "classnames";
import Button from "./Button";
import DestroyButton from "./DestroyButton";

const PhoneBlock = ({
  id,
  number,
  phoneType,
  primary,
  displayEdit,
  editUrl,
  displayDestroy,
  destroyAction
}) => {
  if (!id) {
    return null;
  }
  const values = {
    MOBILE: { name: "Mobile", icon: "mobile-alt" },
    WORK: { name: "Work", icon: ["far", "briefcase"] },
    WORK_FAX: { name: "Fax", icon: ["far", "fax"] },
    HOME_FAX: { name: "Home Fax", icon: ["far", "fax"] },
    HOME: { name: "Home", icon: "home" },
    PAGER: { name: "Pager", icon: "mobile-alt" },
    OTHER: { name: "Other", icon: ["far", "question-square"] }
  };
  const val = values[phoneType] || {
    icon: ["far", "question-square"],
    name: "Unknown"
  };
  return (
    <div className="phone-block">
      <span style={{ fontWeight: "bold", whiteSpace: "nowrap" }}>
        <NumberFormat
          value={number}
          displayType="text"
          format="(###) ###-####"
          mask="_"
        />
      </span>
      <div style={{ display: "block" }}>
        {primary && (
          <span alt="Primary" title="Primary">
            <FontAwesomeIcon icon="star" className="text-warning" />
          </span>
        )}{" "}
        <span className="text-muted" style={{ marginLeft: "10px" }}>
          <FontAwesomeIcon icon="phone" /> <FontAwesomeIcon icon={val.icon} />{" "}
          {val.name}
        </span>
        <Button
          display={!!displayEdit}
          text=""
          icon="pencil-alt"
          to={editUrl}
          size="sm"
          link
          title="Edit Phone"
        />
        <DestroyButton
          display={!!displayDestroy}
          icon="trash-alt"
          bsStyle="danger"
          destroyAction={() => {
            destroyAction(id);
          }}
          size="sm"
          link
          title="Destroy Phone"
        />
      </div>
    </div>
  );
};
PhoneBlock.propTypes = {
  id: PropTypes.string,
  number: PropTypes.string,
  phoneType: PropTypes.string,
  primary: PropTypes.bool,
  displayEdit: PropTypes.bool,
  displayDestroy: PropTypes.bool,
  editUrl: PropTypes.string,
  destroyAction: PropTypes.func
};
PhoneBlock.defaults = {
  number: "",
  phoneType: "",
  primary: false
};
export default PhoneBlock;
