import Auth0Auth from "lib/auth0_auth";

export default {
  login: () => {
    let auth = new Auth0Auth();
    auth.login();
  },
  logout: () => {
    let auth = new Auth0Auth();
    auth.logout();
  },
  processLogin: async url_hash => {
    let auth = new Auth0Auth();
    return new Promise((resolve, reject) => {
      const auth = new Auth0Auth();
      if (/access_token|id_token|error/.test(url_hash)) {
        auth.handleAuthentication(url_hash, resolve);
      }
    });
  },
  renewToken: () => {
    const auth = new Auth0Auth();

    return auth.renewToken();
  },
  fetchAuthTokens: () => {
    const auth = new Auth0Auth();
    return auth.tokens();
  },
  isAuthenticated: () => {
    const auth = new Auth0Auth();
    return auth.isAuthenticated();
  },
  expiresAt: () => {
    const auth = new Auth0Auth();
    return auth.getExpiresAt();
  }
};
