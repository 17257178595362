import React from "react";

import AppConfig from "config/AppConfig";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container-fluid">
        <p className="copyright">
          &copy;
          {new Date().getFullYear()}{" "}
          <a href={AppConfig.appUrl}>Brokerage Engine, Inc.</a>, The Best Way to
          Run Your Brokerage &trade;
        </p>
      </div>
    </footer>
  );
};

export default Footer;
