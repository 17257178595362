import { take, call, put, select } from "redux-saga/effects";

import AT from "../constants/ActionTypes";
//import client from "../config/apolloClient";
import AppConfig from "../config/AppConfig";
//import { push } from "react-router-redux";
import AuthenticationService from "../services/authentication_service";

export default function* logout_cleanup(action) {
  //console.log("Action", action);

  try {
    AuthenticationService.logout();
    //  client.resetStore();
    yield put(push("/login"));
    if (action && action.payload && action.payload.resolve) {
      action.meta.resolve();
    }
  } catch (error) {
    if (action && action.meta && action.meta.reject) {
      action.meta.reject(error);
    }
  }
}
