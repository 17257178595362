import React, { Component } from "react";

import Gravatar from "react-gravatar";

import classnames from "classnames";

import user1 from "src/static/avatars/user_01.png";
import user2 from "src/static/avatars/user_02.png";
import user3 from "src/static/avatars/user_03.png";
import user4 from "src/static/avatars/user_04.png";
import user5 from "src/static/avatars/user_05.png";
import user6 from "src/static/avatars/user_06.png";
import user7 from "src/static/avatars/user_07.png";
import user8 from "src/static/avatars/user_08.png";
import user9 from "src/static/avatars/user_09.png";
import user10 from "src/static/avatars/user_10.png";
import user11 from "src/static/avatars/user_11.png";
import user12 from "src/static/avatars/user_12.png";
import user13 from "src/static/avatars/user_13.png";
import user14 from "src/static/avatars/user_14.png";
import user15 from "src/static/avatars/user_15.png";
import user16 from "src/static/avatars/user_16.png";
import user17 from "src/static/avatars/user_17.png";
import user18 from "src/static/avatars/user_18.png";
import user19 from "src/static/avatars/user_19.png";
import user20 from "src/static/avatars/user_20.png";
import user21 from "src/static/avatars/user_21.png";
import user22 from "src/static/avatars/user_22.png";
import user23 from "src/static/avatars/user_23.png";
import user24 from "src/static/avatars/user_24.png";
import user_unknown from "src/static/avatars/user_unknown.png";

export default class Avatar extends Component {
  render() {
    const { email, size, personalPhotoUrl } = this.props;
    const img = {
      a: user1,
      b: user2,
      c: user3,
      d: user4,
      e: user24,
      f: user5,
      g: user6,
      h: user7,
      i: user8,
      j: user9,
      k: user23,
      l: user10,
      m: user11,
      n: user12,
      o: user13,
      p: user14,
      q: user15,
      r: user16,
      s: user17,
      t: user18,
      u: user19,
      v: user20,
      w: user21,
      x: user22,
      y: user23,
      z: user24
    };
    if (personalPhotoUrl) {
      return (
        <img
          title={`Profile for ${email}`}
          src={personalPhotoUrl}
          height={size}
          width={size}
          className="react-gravatar avatar"
        />
      );
    }
    /* return (
      <img
        alt={`Fake Gravatar for ${email}`}
        src={img[email ? email.charAt(0).toLowerCase() : "a"]}
        height={size}
        width={size}
        className="react-gravatar avatar"
      />
    ); 
    return (
      <Gravatar
        className="avatar border-gray"
        size={size}
        email={email || "demo@brokerageengine.com"}
      />
    ); */
    return (
      <img
        title={`Profile for ${email}`}
        src={user_unknown}
        height={size}
        width={size}
        className="react-gravatar avatar"
      />
    );
  }
}
