import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert } from "react-bootstrap";
import Button from "./Button";

const ItemChosen = ({
  value,
  detail,
  chosenItemDetail,
  clearValue,
  choiceLabel
}) => {
  if (value === undefined || value === "" || value === null) {
    return (
      <div className="text-warning" style={{ marginTop: "8px" }}>
        <FontAwesomeIcon icon="exclamation-triangle" />{" "}
        {choiceLabel || "Not Chosen"}
      </div>
    );
  }
  if (detail && chosenItemDetail) {
    return chosenItemDetail({ value, detail, clearValue });
  }
  return (
    <div>
      {value}
      <Button onClick={clearValue} icon="times" color="danger" link />
    </div>
  );
};
export default ItemChosen;
