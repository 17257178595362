import React from "react";
import ServerError from "./ServerError";

const ErrorFromForm = ({ errors }) => {
  if (errors === null) {
    return null;
  }
  return <ServerError errors={errors} />;
};

export default ErrorFromForm;
