import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

export default class PanelHeader extends React.Component {
  render() {
    const { style, classOpts, children, onClick } = this.props;
    return (
      <div
        className={`panel-heading ${classOpts}`}
        style={style}
        onClick={onClick}
      >
        <div style={{ fontSize: "1.2em" }}>{children}</div>
      </div>
    );
  }
}

PanelHeader.propTypes = {
  // lastReply: PropTypes.object.isRequired
};
