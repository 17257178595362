import { createSelector } from "reselect";

const loginSelector = state => state.settings.login_pending;

export default createSelector([loginSelector], login_pending => {
  if (login_pending) {
    return true;
  } else {
    return false;
  }
});
