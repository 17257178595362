import { call, put } from "redux-saga/effects";

import AT from "../constants/ActionTypes";

import AuthenticationService from "../services/authentication_service";
import AppConfig from "../config/AppConfig";

export default function* service_login(action) {
  // console.log("Action", action);

  try {
    //   console.log("Triggering Login setup")

    //Figure out if they are already logged in?
    const already_logged_in = AuthenticationService.isAuthenticated();
    if (already_logged_in) {
      console.log("They are already logged in");
      yield put({
        type: AT.LOG_IN_USER,

        meta: {},
        payload: AuthenticationService.fetchAuthTokens()
      });

      //action.payload.history.push("/");
      window.location = AppConfig.appUrl;
      yield put({ type: AT.SERVICE_LOGIN_DONE });
    } else {
      yield put({ type: AT.LOG_OUT_USER }); //Just to be safe log out first.
      yield call(AuthenticationService.login);
    }
  } catch (error) {
    // yield put({ type: AT.SERVICE_LOGIN_FAILURE, payload: error });
    yield put({ type: AT.SERVICE_LOGIN_DONE });

    if (action && action.meta && action.meta.reject) {
      action.meta.reject(error);
    }
  }
}
