import React, { Component } from "react";

export default class CardSubTitle extends Component {
  render() {
    const {
      cardClass,
      subtitle,
      category,
      classes,
      contentClass,
      id,
      content,
      legend,
      stats,
      children,
      statsIcon,
      display
    } = this.props;
    if (display !== undefined && display !== true) {
      return null;
    }
    return (
      <div className={"cardtitle " + cardClass}>
        <h4 className="title">{content || children}</h4>
      </div>
    );
  }
}
