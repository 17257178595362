import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import classnames from "classnames";

import { Table, Alert, ButtonToolbar, Clearfix } from "react-bootstrap";
import Button from "components/shared/Button";

const Note = ({ note }) => {
  if (!note) {
    return null;
  }
  return (
    <p>
      <FontAwesomeIcon
        icon={["far", "sticky-note"]}
        className="text-warning"
        style={{ marginRight: "3px", opacity: 0.7 }}
      />
      {note}
    </p>
  );
};

export default Note;
