import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  FormGroup,
  Col,
  FormControl,
  ControlLabel,
  InputGroup
} from "react-bootstrap";

import { Required } from "components/shared";

const TextInputFieldControl = field => (
  <FormControl
    {...field.input}
    onBlur={field.onBlur}
    onFocus={field.onFocus}
    type={field.type ? field.type : "text"}
    disabled={field.disabled}
    placeholder={field.placeholder}
  />
);

export default class FieldInput extends React.Component {
  state = { focused: null };
  onFocus = () => {
    this.setState({ focus: true });
  };
  onBlur = () => {
    this.setState({ focus: false });
  };
  render() {
    const field = this.props;

    //  console.log("Field is:", field);
    if (field.activeFieldList) {
      if (!field.activeFieldList.includes(field.input.name)) {
        return null;
      }
    }
    if (field.disabledFieldList) {
      if (field.disabledFieldList.includes(field.input.name)) {
        return null;
      }
    }
    if (field.type === "checkbox") {
      if (field.label) {
        return (
          <FormGroup controlId={`form-${field.input.name}`}>
            <Col componentClass={ControlLabel} sm={3}>
              {field.label}{" "}
              {field.required && (
                <span>
                  <Required isRequired />
                </span>
              )}
            </Col>
            <Col sm={9} style={{ marginBottom: "6px" }}>
              <input
                {...field.input}
                type={field.type ? field.type : "text"}
                disabled={field.disabled}
                className="checkbox"
              />
              {field.hint && <span className="text-info">{field.hint}</span>}
              {field.meta.touched &&
                field.meta.error && (
                  <span className="text-danger">
                    {" "}
                    <FontAwesomeIcon icon="exclamation-triangle" />{" "}
                    {field.meta.error}
                  </span>
                )}
            </Col>
          </FormGroup>
        );
      }
    }
    if (field.type === "radio") {
      if (field.label) {
        return (
          <FormGroup controlId={`form-${field.input.name}`}>
            <Col componentClass={ControlLabel} sm={3}>
              {field.label}{" "}
              {field.required && (
                <span>
                  <Required isRequired />
                </span>
              )}
            </Col>
            <Col sm={9}>
              <div className="radio">
                <label>
                  <input
                    {...field.input}
                    type={field.type ? field.type : "text"}
                    disabled={field.disabled}
                    checked={
                      field.checked || field.input.checked ? "checked" : false
                    }
                    className="radio"
                  />
                  {field.hint && (
                    <span className="text-info">{field.hint}</span>
                  )}
                  {field.meta.touched &&
                    field.meta.error && (
                      <span className="text-danger">
                        {" "}
                        <FontAwesomeIcon icon="exclamation-triangle" />{" "}
                        {field.meta.error}
                      </span>
                    )}
                </label>
              </div>
            </Col>
          </FormGroup>
        );
      }
    }
    if (field.type === "textarea") {
      if (field.label) {
        return (
          <FormGroup controlId={`form-${field.input.name}`}>
            <Col componentClass={ControlLabel} sm={3}>
              {field.label}{" "}
              {field.required && (
                <span>
                  <Required isRequired />
                </span>
              )}
            </Col>
            <Col sm={9} style={{ marginBottom: "6px" }}>
              <FormControl
                {...field.input}
                componentClass="textarea"
                disabled={field.disabled}
                placeholder={field.placeholder}
              />
              {field.hint && <span className="text-info">{field.hint}</span>}
              {field.meta.touched &&
                field.meta.error && (
                  <span className="text-danger">
                    {" "}
                    <FontAwesomeIcon icon="exclamation-triangle" />{" "}
                    {field.meta.error}
                  </span>
                )}
            </Col>
          </FormGroup>
        );
      }
      return (
        <FormGroup controlId={`form-${field.input.name}`}>
          <FormControl
            {...field.input}
            componentClass="textarea"
            disabled={field.disabled}
            placeholder={field.placeholder}
            style={{ zIndex: 1 }}
          />
          {field.hint && <span className="text-info">{field.hint}</span>}
          {field.meta.touched &&
            field.meta.error && (
              <span className="text-danger">
                {" "}
                <FontAwesomeIcon icon="exclamation-triangle" />{" "}
                {field.meta.error}
              </span>
            )}
        </FormGroup>
      );
    }
    if (field.label) {
      return (
        <FormGroup controlId={`form-${field.input.name}`}>
          <Col componentClass={ControlLabel} sm={3}>
            {field.label}{" "}
            {field.required && (
              <span>
                <Required isRequired />
              </span>
            )}
          </Col>
          <Col sm={9} style={{ marginBottom: "6px" }}>
            {field.phone && (
              <InputGroup
                style={{ zIndex: 0 }}
                className={this.state.focus && "input-group-focus"}
              >
                <InputGroup.Addon>
                  <b>
                    <FontAwesomeIcon icon="phone" />
                  </b>
                </InputGroup.Addon>
                <TextInputFieldControl
                  {...field}
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                />
              </InputGroup>
            )}

            {field.currency && (
              <InputGroup
                style={{ zIndex: 0 }}
                className={this.state.focus && "input-group-focus"}
              >
                <InputGroup.Addon>
                  <b>
                    <FontAwesomeIcon icon="dollar-sign" size="lg" />
                  </b>
                </InputGroup.Addon>
                <TextInputFieldControl
                  {...field}
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                />
              </InputGroup>
            )}

            {field.percent && (
              <InputGroup style={{ zIndex: 0 }}>
                <TextInputFieldControl {...field} />
                <InputGroup.Addon>
                  <b>
                    <FontAwesomeIcon icon="percent" size="lg" />
                  </b>
                </InputGroup.Addon>
              </InputGroup>
            )}
            {!field.phone &&
              !field.currency &&
              !field.percent && <TextInputFieldControl {...field} />}

            {field.hint && <span className="text-info">{field.hint}</span>}
            {field.meta.touched &&
              field.meta.error && (
                <span className="text-danger">
                  {" "}
                  <FontAwesomeIcon icon="exclamation-triangle" />{" "}
                  {field.meta.error}
                </span>
              )}
          </Col>
        </FormGroup>
      );
    }
    return (
      <FormGroup controlId={`form-${field.input.name}`}>
        {field.address && (
          <InputGroup
            style={{ zIndex: 0 }}
            className={this.state.focus && "input-group-focus"}
          >
            <InputGroup.Addon>
              <b>
                <FontAwesomeIcon icon="map-marker-alt" />
              </b>
            </InputGroup.Addon>
            <TextInputFieldControl
              {...field}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
            />
          </InputGroup>
        )}
        {field.company && (
          <InputGroup
            style={{ zIndex: 0 }}
            className={this.state.focus && "input-group-focus"}
          >
            <InputGroup.Addon>
              <b>
                <FontAwesomeIcon icon="building" />
              </b>
            </InputGroup.Addon>
            <TextInputFieldControl
              {...field}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
            />
          </InputGroup>
        )}
        {field.currency && (
          <InputGroup
            className={this.state.focus && "input-group-focus"}
            style={{ zIndex: 0 }}
          >
            <InputGroup.Addon>
              <FontAwesomeIcon icon="dollar-sign" size="lg" />
            </InputGroup.Addon>
            <TextInputFieldControl {...field} />
          </InputGroup>
        )}
        {field.date && (
          <InputGroup
            style={{ zIndex: 0 }}
            className={this.state.focus && "input-group-focus"}
          >
            <InputGroup.Addon>
              <b>
                <FontAwesomeIcon icon={["far", "calendar"]} />
              </b>
            </InputGroup.Addon>
            <TextInputFieldControl
              {...field}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
            />
          </InputGroup>
        )}
        {field.email && (
          <InputGroup
            style={{ zIndex: 0 }}
            className={this.state.focus && "input-group-focus"}
          >
            <InputGroup.Addon>
              <b>
                <FontAwesomeIcon icon="envelope" />
              </b>
            </InputGroup.Addon>
            <TextInputFieldControl
              {...field}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
            />
          </InputGroup>
        )}
        {field.percent && (
          <InputGroup className="input-group-focus">
            <TextInputFieldControl {...field} />
            <InputGroup.Addon>
              <FontAwesomeIcon icon="percent" />
            </InputGroup.Addon>
          </InputGroup>
        )}
        {field.person && (
          <InputGroup
            style={{ zIndex: 0 }}
            className={this.state.focus && "input-group-focus"}
          >
            <InputGroup.Addon>
              <b>
                <FontAwesomeIcon icon="user" size="lg" />
              </b>
            </InputGroup.Addon>
            <TextInputFieldControl
              {...field}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
            />
          </InputGroup>
        )}
        {field.phone && (
          <InputGroup
            style={{ zIndex: 0 }}
            className={this.state.focus && "input-group-focus"}
          >
            <InputGroup.Addon>
              <b>
                <FontAwesomeIcon icon="phone" />
              </b>
            </InputGroup.Addon>
            <TextInputFieldControl
              {...field}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
            />
          </InputGroup>
        )}
        {field.tags && (
          <InputGroup
            className={this.state.focus && "input-group-focus"}
            style={{ zIndex: 0 }}
          >
            <InputGroup.Addon>
              <FontAwesomeIcon icon="tags" />
            </InputGroup.Addon>
            <TextInputFieldControl {...field} />
          </InputGroup>
        )}
        {!field.address &&
          !field.company &&
          !field.currency &&
          !field.date &&
          !field.email &&
          !field.percent &&
          !field.person &&
          !field.phone &&
          !field.tags && <TextInputFieldControl {...field} />}
        {field.meta.touched &&
          field.meta.error && (
            <span className="text-danger">
              <FontAwesomeIcon icon="exclamation-triangle" /> {field.meta.error}
            </span>
          )}
        {field.hint && <span className="text-info">{field.hint}</span>}
      </FormGroup>
    );
  }
}
