//If you modify this file - you need to make sure the deploy still works - DJE
export default Object.freeze({
  appEnv: 'staging',
  auth0ClientId: 'qT1IhpJCLM1ikPKJysFEAJZ6hGBj4ZO9',
  auth0Domain: 'brokerageengine.auth0.com',
  auth0CallbackURL: 'https://aa.dev.brokerageengine.com/callback',
  auth0Audience: 'aa-brokerage-engine-api',
  graphqlUrl: 'https://aa.dev.brokerageengine.com/graphql',
  appUrl: 'https://aa.dev.brokerageengine.com/',
  sentryUrl: 'https://980589f8fc3c47989a96681e36bf334c@sentry.io/301785',
  actionCableUrl: 'wss://aa.dev.brokerageengine.com/cable',
  lastDeploy: '2018-11-11T08:32:47-06:00',
  versionHash: 'f34b102'

});
