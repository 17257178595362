import React from "react";
import PropTypes from "prop-types";

export default class PanelFooter extends React.Component {
  render() {
    return (
      <div className="panel-footer" style={this.props.style}>
        {this.props.children}
      </div>
    );
  }
}

PanelFooter.propTypes = {
  // lastReply: PropTypes.object.isRequired
};
