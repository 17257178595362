import React, { Component } from "react";

import Button from "./Button";
import ErrorWithButton from "./ErrorWithButton";

export default class ConfirmButton extends Component {
  state = {
    currentIcon: this.props.initialIcon ? this.props.initialIcon : "trash-alt",
    currentText: this.props.initialText ? this.props.initialText : "",
    currentColor: this.props.initialColor ? this.props.initialColor : "default",
    countdownIcon: "hourglass-start",
    countdownColor: "success",
    confirmAllowed: false,
    disabled: false,
    delayInMsec: 1500,
    confirmDelayStepInMsec: 0, //Recalced
    showErroInMsec: 20000,
    cancelConfirmInMsec: 6000,
    cancelConfirmStepInMsec: 0, //Recalced
    errors: null
  };
  triggerConfirm = e => {
    const config = [
      {
        currentIcon: "battery-empty",
        currentText: this.props.actionText ? this.props.actionText : "Delete",
        currentColor: "default",
        disabled: true
      },
      {
        currentIcon: "battery-quarter",
        currentColor: "default"
      },
      {
        currentIcon: "battery-half",
        currentColor: "default"
      },
      {
        currentIcon: "battery-three-quarters",
        currentColor: "warning"
      },
      {
        currentIcon: "battery-full",
        currentColor: "warning"
      }
    ];
    this.setState({
      confirmDelayStepInMsec: this.state.delayInMsec / config.length
    });
    this.deleteWait(config);
  };
  triggerCountdown = e => {
    const config = [
      {
        countdownIcon: "hourglass-start",
        countdownColor: "default"
      },
      {
        countdownIcon: "hourglass-half",
        countdownColor: "warning"
      },
      {
        countdownIcon: "hourglass-end",
        countdownColor: "danger"
      }
    ];
    this.setState({
      cancelConfirmStepInMsec: this.state.cancelConfirmInMsec / config.length
    });
    this.cancelWait(config);
  };

  deleteWait = args => {
    const config = args.shift();

    this.clearConfirmTimeout = setTimeout(() => {
      if (args.length === 0) {
        this.allowDelete();
      } else {
        this.deleteWait(args);
      }
    }, this.state.confirmDelayStepInMsec);
    this.setState(config);
  };

  cancelWait = args => {
    const config = args.shift();

    this.clearCountdownTimeout = setTimeout(() => {
      if (args.length === 0) {
        this.cancelConfirm();
      } else {
        this.cancelWait(args);
      }
    }, this.state.cancelConfirmStepInMsec);
    this.setState(config);
  };

  allowDelete = () => {
    this.setState({
      currentIcon: this.props.actionIcon ? this.props.actionIcon : "trash-alt",
      currentText: this.props.actionText ? this.props.actionText : "Delete",
      currentColor: this.props.actionColor ? this.props.actionColor : "danger",
      disabled: false,
      confirmAllowed: true
    });

    clearTimeout(this.clearConfirmTimeout);
    this.triggerCountdown();
  };
  clickCancel = e => {
    this.cancelConfirm();
  };
  cancelConfirm = errors => {
    this.setState({
      currentIcon: this.props.initialIcon
        ? this.props.initialIcon
        : "trash-alt",
      currentText: this.props.initialText ? this.props.initialText : "",
      currentColor: this.props.initialColor
        ? this.props.initialColor
        : "default",
      disabled: false,
      confirmAllowed: false,
      errors: errors ? errors : null
    });
    clearTimeout(this.clearConfirmTimeout);
    clearTimeout(this.clearCountdownTimeout);
    if (errors) {
      this.clearConfirmTimeout = setTimeout(() => {
        this.setState({ errors: null });
      }, this.state.showErroInMsec);
    }
  };
  componentWillUnmount() {
    clearTimeout(this.clearConfirmTimeout);
    clearTimeout(this.clearCountdownTimeout);
  }

  wrapConfirmAction = e => {
    //  console.log("Wrapping ConfirmAction");
    this.setState({
      currentIcon: "spinner",
      currentText: "Submitting...",
      currentColor: "default",
      disabled: true
    });
    try {
      Promise.resolve(this.props.confirmAction(e))
        .then(data => {
          //  console.log("back with ",data)
          if (data && data.errors) {
            this.setState({
              errors: data.errors
            });
          }
          this.cancelConfirm();
        })
        .catch(error => {
          //  console.log(" Confirm Button Error ", error);
          this.cancelConfirm(error);
        });
    } catch (error2) {
      // console.log("Caught in error2", error2);
      this.cancelConfirm(error2);
    }
  };
  render() {
    const {
      confirmAction,
      destroyAction,
      disconnectAction,
      onClick,
      initialIcon,
      initialText,
      initialColor,
      actionIcon,
      actionText,
      actionColor,
      size,
      ...rest
    } = this.props;
    const {
      currentIcon,
      currentText,
      currentColor,
      countdownIcon,
      countdownColor,
      errors,
      disabled,
      confirmAllowed
    } = this.state;
    const clickAction = confirmAllowed
      ? this.wrapConfirmAction
      : this.triggerConfirm;

    if (onClick) {
      console.warn(
        "Passing an onClick to the delete button is not supported. Please change to confirmAction"
      );
    }
    if (disconnectAction) {
      console.warn(
        "Passing an disconnectAction to the Confirm button is not supported. Please change to confirmAction"
      );
    }
    if (destroyAction) {
      console.warn(
        "Passing an destroyAction to the Confirm button is not supported. Please change to confirmAction"
      );
    }
    if (confirmAllowed) {
      return (
        <span
          style={{
            display: "inline-block",
            whiteSpace: "nowrap",
            zIndex: 9999
          }}
        >
          <Button
            {...rest}
            size={size}
            icon={currentIcon}
            text={currentText}
            disabled={disabled}
            onClick={clickAction}
            color={currentColor}
          />
          <Button
            size={size}
            disabled
            link
            icon={countdownIcon}
            color={countdownColor}
            styleOpts={{
              paddingLeft: "0px",
              marginLeft: "6px",
              paddingRight: "0px",
              marginRight: "6px"
            }}
          />
          <Button
            size={size}
            link
            icon={["far", "window-close"]}
            color="default"
            title={"Cancel"}
            styleOpts={{
              paddingLeft: "0px",
              marginLeft: "9px",
              paddingRight: "0px",
              marginRight: "9px"
            }}
            onClick={this.clickCancel}
          />
          <ErrorWithButton errors={errors} />
        </span>
      );
    } else {
      return (
        <span
          style={{
            display: "inline-block",
            whiteSpace: "nowrap",
            zIndex: 9999
          }}
        >
          <Button
            {...rest}
            size={size}
            icon={currentIcon}
            text={currentText}
            color={currentColor}
            disabled={disabled}
            onClick={clickAction}
          />
          <ErrorWithButton errors={errors} />
        </span>
      );
    }
  }
}
