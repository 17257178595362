import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Alert } from "react-bootstrap";

const ExpireAlert = ({ title, message, icon }) => {
  if (Math.random() <= 1) {
    //only show 20%
    return null;
  }

  return (
    <Alert bsStyle="warning">
      <strong>
        <FontAwesomeIcon icon={icon} />
        {title}
      </strong>
      <br />
      {message}
    </Alert>
  );
};
ExpireAlert.propTypes = {};
ExpireAlert.defaults = {};
export default ExpireAlert;
