import React from "react";
import { graphql } from "react-apollo";
import { loader as graphqlLoader } from "graphql.macro";

const AddressTypeListQL = graphqlLoader(
  "src/graphql/queries/AddressTypeList.graphql"
);
import FieldEnumSelect from "./FieldEnumSelect";

export default graphql(AddressTypeListQL)(FieldEnumSelect);
