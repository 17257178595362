import Immutable from "seamless-immutable";
import LocalStore from "store2";
import AT from "constants/ActionTypes";

const CURRENT_VERISON = 4;
const BREADCRUMB_KEY = "accountBreadCrumb";
const initialState = Immutable.from({
  version: CURRENT_VERISON,
  accountId: "UNSET",
  crumbs: {
    dict: {}
  }
});
const reducer = {};

export default function breadcrumbs(
  state = initialState,
  action = { type: null }
) {
  return action && action.type && reducer[action.type]
    ? reducer[action.type](state, action)
    : state;
}

reducer[AT.LOG_OUT_USER] = (state, _) => {
  // return state.merge(initialState);
  return state;
};

reducer[AT.SAVE_SELECTED_ACCOUNT] = (state, action) => {
  const { accountId } = action.payload;

  const newState = Immutable.from({
    version: CURRENT_VERISON,
    accountId: accountId,
    crumbs: state.crumbs
  });

  LocalStore(BREADCRUMB_KEY, newState);
  return newState;
};
reducer[AT.LOAD_CRUMBS] = (state, action) => {
  const localVersion = LocalStore(BREADCRUMB_KEY);

  return localVersion && localVersion.version === CURRENT_VERISON
    ? state.merge(localVersion)
    : state;
};
reducer[AT.LOAD_SELECTED_ACCOUNT] = (state, action) => {
  const localVersion = LocalStore(BREADCRUMB_KEY);

  return localVersion && localVersion.version === CURRENT_VERISON
    ? state.merge(localVersion)
    : state;
};

reducer[AT.ADD_CRUMB] = (state, action) => {
  // console.log("Add Crumb?", action);
  const { type, label, id, url } = action.payload;
  let crumbs =
    state && state.crumbs
      ? Immutable.asMutable(state.crumbs, { deep: true })
      : {};

  // console.log("Crumbs is?",state, crumbs)
  const crumbDict = crumbs.dict ? crumbs.dict : {};
  if (!crumbs[state.accountId]) {
    crumbs[state.accountId] = {};
  }
  const crumbList = crumbs[state.accountId][type] || [];

  const maxSize = 4;
  if (crumbList.indexOf(id) !== -1) {
    const location = crumbList.indexOf(id);

    crumbList.splice(location, 1);
  } else if (crumbList.length > maxSize) {
    while (crumbList.length > maxSize) {
      const currentId = crumbList.pop();
      //  console.log("Removing the",currentId)

      delete crumbDict[currentId];
    }
  }
  crumbList.unshift(id);
  crumbDict[id] = { type, label, id, url };

  crumbs[state.accountId][type] = crumbList;
  crumbs.dict = crumbDict;
  // console.log("GOing to save ", crumbs)
  const newState = Immutable.from({
    version: CURRENT_VERISON,
    accountId: state.accountId,
    crumbs
  });

  LocalStore(BREADCRUMB_KEY, newState);
  return newState;
};

/*
  const brokerageTransactionId =
      nextProps.match.params.brokerageTransactionId;
    if (nextProps.data && !nextProps.data.loading) {
      console.log("Incoming", nextProps.data);
      let breadcrumbList = LocalStore("breadcrumbList");
      const {
        node: {
          transactionNumber,
          contract: { properties: { edges: properties } }
        }
      } = nextProps.data;
      let description;
      if (properties && properties[0] && properties[0].node) {
        description = properties[0].node.address.streetAddress;
      } else {
        description = transactionNumber;
      }
      if (!breadcrumbList) {
        LocalStore("breadcrumbList", []);
        breadcrumbList = [];
      }
      if (breadcrumbList.length > 5) {
        while (breadcrumbList.length > 4) {
          const currentId = breadcrumbList.shift();
          LocalStore.remove(currentId);
        }

        LocalStore("breadcrumbList", breadcrumbList);
      }

      const location = breadcrumbList.indexOf(brokerageTransactionId);
      if (location !== -1) breadcrumbList.splice(location, 1);

      breadcrumbList.push(brokerageTransactionId);
      LocalStore("breadcrumbList", breadcrumbList);

      LocalStore(brokerageTransactionId, {
        url: nextProps.match.url,
        description: description
      });
    }
 */
