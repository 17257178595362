import React, { Component } from "react";
import ui from "redux-ui";

import {
  FormGroup,
  Col,
  ControlLabel,
  FormControl,
  InputGroup,
  ButtonGroup
} from "react-bootstrap";

import { Button, ItemChoice, ItemChosen, Required } from "components/shared";

@ui({})
export default class FieldItemsChoose extends Component {
  static propTypes = {};
  static defaultProps = {};
  updateSearchText = evt => {
    this.props.updateUI("currentSearchText", evt.target.value);
  };
  clearValue = clearedItem => {
    const {
      updateUI,

      getItemId,
      ui: { chosenItems }
    } = this.props;
    updateUI("wipeItem", true);

    const newChosenItems = [...chosenItems];
    const clearItemId = getItemId ? getItemId(clearedItem) : clearedItem.id;

    const idIdx = newChosenItems.findIndex(item => {
      const itemId = getItemId ? getItemId(item) : item.id;
      return itemId === clearItemId;
    });
    if (idIdx > -1) {
      newChosenItems.splice(idIdx, 1);
    }
    this.updateChosenItems(newChosenItems);
  };
  clearSearch = () => {
    const search = Object.assign({}, this.props.ui.search);
    search.searchText = null;
    this.props.updateUI("currentSearchText", "");
    this.props.updateUI("search", search);
  };
  updateSearch = evt => {
    const search = Object.assign({}, this.props.ui.search);
    search.searchText = this.props.ui.currentSearchText;
    this.props.updateUI("search", search);

    evt.preventDefault();

    this.props.loadMoreEntries(search);
  };
  chooseItem = item => {
    const {
      ui: { chosenItems }
    } = this.props;

    const newChosenItems = [...chosenItems];
    newChosenItems.push(item);

    this.updateChosenItems(newChosenItems);
  };
  updateChosenItems = newChosenItems => {
    const { input, updateUI, syncChosen } = this.props;
    updateUI("wipeItem", true);

    this.clearSearch();

    const ids = newChosenItems.map(item => this.resolveItemId(item));

    input.onChange(ids);

    updateUI("chosenItems", newChosenItems);
    if (syncChosen) {
      syncChosen(newChosenItems);
    }
  };
  resolveItemId = item => {
    const { getItemId } = this.props;
    return getItemId ? getItemId(item) : item.id;
  };
  componentWillReceiveProps(nextProps) {
    if (
      (this.props.ui.chosenItems === null ||
        this.props.ui.chosenItems === []) &&
      !nextProps.ui.wipeItems &&
      nextProps.chosenItems
    ) {
      this.chooseItem(nextProps.chosenItems);
    }
    if (nextProps.overrideChosenItem) {
      this.chooseItem(nextProps.overrideChosenItem);
    }
  }
  onKeyDown = event => {
    const { keyCode } = event;
    switch (keyCode) {
      case 9: // TAB
      case 13: // ENTER
        event.stopPropagation();
        this.updateSearch(event);
        break;
      case 27: // escape
        event.preventDefault();
        event.stopPropagation();
        this.clearSearch();
        break;
      default:
        break;
    }
  };

  renderSearch = () => {
    const {
      input,
      loading,
      edges,
      itemChoiceDetail,
      choiceLabel,
      ui: { currentSearchText, chosenItems }
    } = this.props;
    const chosenIds = chosenItems.map(item => this.resolveItemId(item));
    let itemChoices = edges.map(
      ({ node: item }) =>
        chosenIds.includes(this.resolveItemId(item)) ? null : item
    );
    itemChoices = itemChoices.filter(item => item);

    return (
      <div>
        <FormGroup controlId={`form-${input.name}-search`}>
          <Col componentClass={ControlLabel} sm={3} />
          <Col mdOffset={3} sm={8} style={{ marginBottom: "6px" }}>
            <InputGroup style={{ zIndex: 0 }}>
              <FormControl
                type="text"
                value={currentSearchText}
                onChange={this.updateSearchText}
                onKeyDown={this.onKeyDown}
              />
              <InputGroup.Button>
                <Button
                  onClick={this.clearSearch}
                  icon="times"
                  color="default"
                />
                <Button
                  onClick={this.updateSearch}
                  icon="search"
                  text="Search"
                />
              </InputGroup.Button>
            </InputGroup>
          </Col>
        </FormGroup>
        <FormGroup>
          <Col componentClass={ControlLabel} sm={3}>
            {choiceLabel}
          </Col>
          <Col sm={9} style={{ marginBottom: "6px" }}>
            <ButtonGroup vertical block>
              {!loading &&
                itemChoices.map(item => (
                  <ItemChoice
                    key={item.id}
                    item={item}
                    choose={this.chooseItem}
                    itemChoiceDetail={itemChoiceDetail}
                  />
                ))}
            </ButtonGroup>
          </Col>
        </FormGroup>
      </div>
    );
  };
  render() {
    const {
      input,
      required,
      hint,
      meta,
      label,
      chosenItemDetail,
      choiceLabel,
      ui: { chosenItems }
    } = this.props;

    return (
      <div>
        <FormGroup controlId={`form-${input.name}`}>
          <Col componentClass={ControlLabel} sm={3}>
            {label}{" "}
            {required && (
              <span>
                <Required isRequired />
              </span>
            )}
          </Col>
          <Col sm={9} style={{ marginBottom: "6px" }}>
            {chosenItems.map(chosenItem => (
              <ItemChosen
                key={chosenItem.key}
                value={chosenItem ? chosenItem.id : null}
                detail={chosenItem}
                chosenItemDetail={chosenItemDetail}
                clearValue={() => this.clearValue(chosenItem)}
                choiceLabel={choiceLabel}
              />
            ))}

            {hint && <span className="text-info">{hint}</span>}

            {meta.touched &&
              meta.error && <span className="text-error">{meta.error}</span>}
          </Col>
        </FormGroup>
        {this.renderSearch()}
      </div>
    );
  }
}
