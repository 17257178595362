import React, { Component } from "react";

import { FieldSelect } from "components/shared";

export default class FieldZeroOrBaseSelect extends Component {
  static propTypes = {};
  static defaultProps = {};

  buildOptions = () => [
    { name: "Base Rate", value: "BASE_RATE", disabled: false },
    { name: "Zero Rate", value: "ZERO_RATE", disabled: false }
  ];

  render() {
    const options = this.buildOptions();
    return <FieldSelect {...this.props} options={options} />;
  }
}
