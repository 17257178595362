import AT from "src/constants/ActionTypes";
export default {
  addCrumb(type, label, id, url, accountId, resolve = null, reject = null) {
    return {
      type: AT.ADD_CRUMB,
      meta: {
        resolve,
        reject
      },
      payload: {
        type,
        label,
        id,
        url,
        accountId
      }
    };
  },
  loadCrumbs(resolve = null, reject = null) {
    return {
      type: AT.LOAD_CRUMBS,
      meta: {
        resolve,
        reject
      },
      payload: {}
    };
  }
};
