import React, { Component } from "react";
import ui from "redux-ui";

import { FormGroup, Col, ControlLabel, ButtonGroup } from "react-bootstrap";

import ItemChoice from "./ItemChoice";
import ItemChosen from "./ItemChosen";

@ui({})
export default class FieldItemChoiceSelect extends Component {
  static propTypes = {};
  static defaultProps = {};

  clearValue = () => {
    const { input, updateUI, syncSelected } = this.props;
    updateUI("wipeDetail", true);
    updateUI("chosenDetail", null);
    if (syncSelected) {
      syncSelected(null);
    }
    input.onChange(null);
  };

  chooseDetail = item => {
    const { input, updateUI, syncSelected, getDetailId } = this.props;
    const id = getDetailId ? getDetailId(item) : item.id;

    input.onChange(id);

    updateUI("chosenDetail", item);
    if (syncSelected) {
      syncSelected(item);
    }
  };
  componentWillMount() {
    if (
      this.props.ui.chosenDetail === null &&
      !this.props.ui.wipeDetail &&
      this.props.chosenDetail
    ) {
      this.chooseDetail(this.props.chosenDetail);
    }
  }
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.ui.chosenDetail === null &&
      !nextProps.ui.wipeDetail &&
      nextProps.chosenDetail
    ) {
      this.chooseDetail(nextProps.chosenDetail);
    }
    if (nextProps.overrideChosenDetail) {
      this.chooseDetail(nextProps.overrideChosenDetail);
    }
  }
  renderSelect = () => {
    const { input, loading, error, edges, itemChoiceDetail } = this.props;
    if (input.value || loading) {
      return null;
    }

    return (
      <div>
        <FormGroup>
          <Col mdOffset={3} sm={9} style={{ marginBottom: "6px" }}>
            <ButtonGroup vertical block>
              {!loading &&
                edges.map(({ node: item }) => (
                  <ItemChoice
                    key={item.id}
                    item={item}
                    choose={this.chooseDetail}
                    itemChoiceDetail={itemChoiceDetail}
                  />
                ))}
            </ButtonGroup>
          </Col>
        </FormGroup>
      </div>
    );
  };
  render() {
    const {
      input,
      hint,
      meta,
      choiceLabel,
      selectChoiceLabel,
      ui: { chosenDetail },
      chosenItemDetail
    } = this.props;
    return (
      <div>
        <FormGroup controlId={`form-${input.name}`}>
          {selectChoiceLabel ? (
            <Col componentClass={ControlLabel} sm={3}>
              {selectChoiceLabel}
            </Col>
          ) : (
            <Col componentClass={ControlLabel} sm={3} />
          )}
          <Col sm={9} style={{ marginBottom: "6px" }}>
            <ItemChosen
              value={input.value}
              detail={chosenDetail}
              chosenItemDetail={chosenItemDetail}
              clearValue={this.clearValue}
              choiceLabel={choiceLabel}
            />
            {hint && <span className="text-info">{hint}</span>}
            {meta.touched &&
              meta.error && <span className="text-danger"> {meta.error}</span>}
          </Col>
        </FormGroup>
        {this.renderSelect()}
      </div>
    );
  }
}
