import React from "react";

import { Portal } from "react-portal";
import ServerError from "./ServerError";

const ErrorFromButton = ({ errors }) => {
  if (errors === null) {
    return null;
  }

  return (
    <Portal node={document && document.getElementById("main-error-area")}>
      <ServerError errors={errors} />;
    </Portal>
  );
};

export default ErrorFromButton;
