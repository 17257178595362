import React, { Component } from "react";
import "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HeaderLinks from "./HeaderLinks";

import { Nav, NavItem, NavLink, SidebarBrandingBlock } from "components/shared";
import Breadcrumb from "./Breadcrumb";
import imagine from "skin/src/assets/img/sidebar-6.jpg";

import logo_icon from "static/Brokerage-Engine-icon.png";

class Sidebar extends Component {
  state = { width: window.innerWidth };

  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }
  render() {
    const sidebarBackground = {
      backgroundImage: `url(${imagine})`
    };
    const { location } = this.props;

    let accountName = null;

    return (
      <div
        id="sidebar"
        className="sidebar"
        data-color="black"
        data-image={imagine}
      >
        <div className="sidebar-background" style={sidebarBackground} />

        <SidebarBrandingBlock accountName={accountName} logoIcon={logo_icon} />

        <div className="sidebar-wrapper">
          <Nav>
            {this.state.width <= 991 ? <HeaderLinks {...this.props} /> : null}

            <NavItem location={location} activePath="/dashboard">
              <NavLink
                to="/dashboard"
                className="nav-link"
                activeClassName="active"
              >
                <p>
                  <FontAwesomeIcon icon="signal" size="2x" fixedWidth />{" "}
                  Dashboard
                </p>
              </NavLink>
            </NavItem>
          </Nav>
          <Breadcrumb />
        </div>
      </div>
    );
  }
}

export default Sidebar;
