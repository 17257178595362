import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment"; // eslint-disable-line
// Based on code from https://github.com/erikras/redux-form/issues/1860#issuecomment-315188615
import { FormGroup, Col, ControlLabel } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Required } from "components/shared";

require("components/styles/css/react-datepicker-cssmodules.global.css");

export default class FieldDatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: null
    };
  }

  handleDateChange = date => {
    // console.log("Change:", this.props.input, dates.startDate ? dates.startDate.startOf("day").format() : null,
    //      dates.endDate ? dates.endDate.endOf("day").format() : null,
    // dates);
    //  console.log("Changin got", date && date.format ? date.format() : date);
    this.props.input.onChange(date);
  };

  onKeyDown = event => {
    const { keyCode } = event;
    switch (keyCode) {
      case 9: // TAB
      case 13: // ENTER
        event.stopPropagation();

        this.props.input.onChange(moment(event.target.value, "MM/DD/YYYY"));
        // this.updateSearch(event);
        break;
      case 27: // escape
        event.preventDefault();
        event.stopPropagation();
        this.props.input.onChange(null);
        break;
    }
  };
  render() {
    // console.log("Field props?", this.props);
    const {
      input,
      hint,
      meta,
      label,
      activeFieldList,
      disabledFieldList,
      required
    } = this.props;

    if (activeFieldList) {
      if (!activeFieldList.includes(input.name)) {
        return null;
      }
    }
    if (disabledFieldList) {
      if (disabledFieldList.includes(input.name)) {
        return null;
      }
    }

    const date = input.value ? input.value : null;

    if (label) {
      return (
        <FormGroup controlId={`form-${input.name}`}>
          <Col componentClass={ControlLabel} sm={3}>
            {label}{" "}
            {required && (
              <span>
                <Required isRequired />
              </span>
            )}
          </Col>
          <Col sm={9} style={{ marginBottom: "6px" }}>
            <DatePicker
              onKeyDown={this.onKeyDown}
              selected={date}
              id={`form-${input.name}`}
              openToDate={date || moment()}
              focused={this.state.focused} // PropTypes.bool
              onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
              onChange={this.handleDateChange}
              onSelect={this.handleSelect}
              dateFormat="MM/DD/YYYY"
              onBlur={this.handleOnBlur}
              className="form-control"
            />
            {hint && <span className="text-info"> {hint} </span>}
            {meta.touched &&
              meta.error && (
                <span className="text-danger">
                  <FontAwesomeIcon icon="exclamation-triangle" /> {meta.error}
                </span>
              )}{" "}
          </Col>
        </FormGroup>
      );
    }
    return (
      <FormGroup controlId={`form-${input.name}`}>
        <DatePicker
          onKeyDown={this.onKeyDown}
          selected={date}
          openToDate={date || moment()}
          focused={this.state.focused} // PropTypes.bool
          onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
          onChange={this.handleDateChange}
          onSelect={this.handleSelect}
          dateFormat="MM/DD/YYYY"
          onBlur={this.handleOnBlur}
          style={{ zIndex: 200 }}
        />
        {hint && <span className="text-info"> {hint} </span>}
        {meta.touched &&
          meta.error && (
            <span className="text-danger">
              <FontAwesomeIcon icon="exclamation-triangle" /> {meta.error}
            </span>
          )}{" "}
      </FormGroup>
    );
  }
}
