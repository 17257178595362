import React, { Component } from "react";
import { connect } from "react-redux";
import Raven from "raven-js";

import fontawesome from "@fortawesome/fontawesome-pro";

import { library as fontawesomeLibrary } from "@fortawesome/fontawesome-svg-core";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { fas } from "@fortawesome/pro-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "skin/src/assets/css/bootstrap.min.global.css";
import "skin/src/assets/css/animate.min.global.css";
//Turning off sass - going to use the version we statically generate
//import "skin/src/assets/sass/light-bootstrap-dashboard.global.scss";
import "skin/src/assets/css/light-bootstrap-dashboard.global.css";
import "skin/src/assets/css/demo.global.css";
import "skin/src/assets/css/pe-icon-7-stroke.global.css";

import ErrorBoundaryAppContainer from "components/shared/ErrorBoundaryAppContainer";

import { ErrorFromButton } from "components/shared";
import NotLoggedIn from "components/login/NotLoggedIn";
import AccountMain from "./AccountMain";
import AutoReload from "./AutoReload";

import AuthenticationActions from "actions/AuthenticationActions";
import isAuthenticated from "selectors/isAuthenticated";
import isPendingAuthentication from "selectors/isPendingAuthentication";

fontawesomeLibrary.add(fab, far, fal, fas);

const mapStateToProps = state => {
  return {
    is_authenticated: isAuthenticated(state),
    is_pending_authentication: isPendingAuthentication(state)
  };
};

class Main extends Component {
  state = {
    uncaughtErrors: null,
    messages: []
  };

  constructor(props) {
    super(props);

    this.componentDidMount = this.componentDidMount.bind(this);
    window.onunhandledrejection = e => {
      //console.log("******Uncaught", e);
      this.setState({ uncaughtErrors: e.reason });
    };
  }

  componentDidUpdate(nextProps, nextState) {
    if (nextState.uncaughtErrors) {
      Raven.captureException(nextState.uncaughtErrors);
      this.clearErrorTimeout = setTimeout(() => {
        this.setState(() => ({ uncaughtErrors: null }));
      }, 300);
    }
  }
  componentWillUnmount() {
    clearTimeout(this.clearErrorTimeout);
  }
  componentDidMount() {
    if (!this.props.is_authenticated) {
      this.props.checkAlreadyLoggedIn(this.forceUpdate);
    }

  }

  render() {
    const { is_authenticated, is_pending_authentication, history } = this.props;

    if (is_pending_authentication) {
      return (
        <div className="text-info">
          <p>
            <FontAwesomeIcon icon="spinner" spin size="lg" fixedWidth />{" "}
            Authenticating...
          </p>
        </div>
      );
    }
    if (!is_authenticated) {
      return <NotLoggedIn {...this.props} />;
    }

    return (
      <ErrorBoundaryAppContainer history={history}>
        <ErrorFromButton errors={this.state.uncaughtErrors} />
        <AutoReload url="/version_hash.txt" />
        <AccountMain {...this.props} />
      </ErrorBoundaryAppContainer>
    );
  }
}
export default connect(
  mapStateToProps,
  AuthenticationActions
)(Main);
