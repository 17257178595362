import React, { Component } from "react";

// DJE - Not using the real include for compatability reasons
// import { Document, Page as PDFPage } from "react-pdf/build/entry.webpack";

//import { Document, Page as PDFPage } from "react-pdf";
//pdfjs.GlobalWorkerOptions.workerSrc = `${paths.appSrc}/lib/pdf_worker/pdf.worker.js`;
//pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import { ButtonToolbar } from "react-bootstrap";
import { Button, Card } from "src/components/shared";

export default class PdfDisplay extends Component {
  static propTypes = {};
  static defaultProps = {};

  state = { numPages: null, pageNumber: 1 };

  onDocumentLoad = ({ numPages }) => {
    this.setState({ numPages });
  };

  nextPage = () => {
    if (this.state.pageNumber + 1 <= this.state.numPages) {
      this.setState({ pageNumber: this.state.pageNumber + 1 });
    }
  };
  prevPage = () => {
    if (this.state.pageNumber - 1 > 0) {
      this.setState({ pageNumber: this.state.pageNumber - 1 });
    }
  };

  render() {
    const { pageNumber, numPages } = this.state;
    const { pdf, encoded } = this.props;

    if (!pdf) {
      return null;
    }
    const PageNav = () => {
      return (
        <div style={{ margin: "1rem" }}>
          {numPages > 1 && (
            <ButtonToolbar>
              <Button
                icon={"chevron-left"}
                onClick={this.prevPage}
                disabled={pageNumber === 1}
              />
              <Button
                icon={"chevron-right"}
                onClick={this.nextPage}
                disabled={pageNumber === numPages}
              />
              <div style={{ marginTop: "12px" }}>
                Page {pageNumber} of {numPages}
              </div>
            </ButtonToolbar>
          )}
        </div>
      );
    };
    let file = pdf;
    if (encoded) {
      file = `data:application/pdf;base64,${pdf}`;
    }

    return (
      <Card>
        <PageNav />
        <Document file={file} onLoadSuccess={this.onDocumentLoad}>
          <PDFPage pageNumber={pageNumber} />
        </Document>
        <br />

        <PageNav />
      </Card>
    );
  }
}
