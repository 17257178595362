import React from "react";
import Select from "react-select";
require("react-select/dist/react-select.css");

import { ControlLabel } from "react-bootstrap";
const ObjFieldSelect = props => {
  if (props.label) {
    return (
      <div>
        <ControlLabel>{props.label}</ControlLabel>

        <div>
          <Select
            {...props}
            value={props.input.value}
            searchable={false}
            labelKey={props.labelKey ? props.labelKey : "name"}
            onChange={value => props.input.onChange(value)}
            onBlur={() => props.input.onBlur(props.input.value)}
            options={props.options}
          />
        </div>
      </div>
    );
  } else {
    return (
      <Select
        {...props}
        value={props.input.value}
        searchable={false}
        labelKey={props.labelKey ? props.labelKey : "name"}
        onChange={value => props.input.onChange(value)}
        onBlur={() => props.input.onBlur(props.input.value)}
        options={props.options}
      />
    );
  }
};
export default ObjFieldSelect;
