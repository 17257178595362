//require('dotenv').config()
import envConfig from "./env_config";

// console.log("Env", envConfig);

export default Object.freeze({
  dateFormat: "MM-DD-YYYY h:mma",
  version: "0.0.1",
  dateOnlyFormat: "MM-DD-YYYY",
  appEnv: envConfig.appEnv,
  appUrl: envConfig.appUrl,
  auth0ClientId: envConfig.auth0ClientId,
  auth0Domain: envConfig.auth0Domain,
  auth0CallbackURL: envConfig.auth0CallbackURL,
  auth0Audience: envConfig.auth0Audience,
  graphqlUrl: envConfig.graphqlUrl,
  lastDeploy: envConfig.lastDeploy,
  versionHash: envConfig.versionHash,
  sentryUrl: envConfig.sentryUrl,
  actionCableUrl: envConfig.actionCableUrl,
  dashboardPath: "/dashboard",
  giphyApiKey: "Ov6oNDvMRPuOmeZIWkh5dwTNxRId9JG9"
});
