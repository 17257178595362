import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import classnames from "classnames";

import { DestroyButton, Button, DisconnectButton } from "components/shared";

const AddressTypeBlock = ({ icon, name }) => {
  if (!icon) {
    return null;
  }
  return (
    <span className="text-muted" style={{ marginLeft: "10px" }}>
      <FontAwesomeIcon icon={icon} /> {name}
    </span>
  );
};

const streetStyle = { fontWeight: "bold", fontSize: "1.7rem" };

const AddressBlock = ({
  streetAddress,
  id,
  city,
  state,
  zipcode,
  county,
  mlsNumber,
  addressType,
  primary,
  displayEdit,
  editUrl,
  displayDestroy,
  destroyAction,
  displayDisconnect,
  disconnectAction,
  highlightStreet = true
}) => {
  const values = {
    WORK: { name: "Work", icon: ["far", "briefcase"] },
    HOME: { name: "Home", icon: "home" }
    //    OTHER: { name: "Other", icon: ["far", "question-square"] }
  };

  if (!id) {
    return null;
  }
  const val = values[addressType] || "";
  return (
    <div className="address-block" style={{ marginBottom: ".5rem" }}>
      <span style={highlightStreet ? streetStyle : null}>{streetAddress}</span>
      <Button
        display={!!displayEdit}
        text=""
        icon="pencil-alt"
        to={editUrl}
        link
        title="Edit Address"
      />
      <DestroyButton
        display={!!displayDestroy}
        icon="trash-alt"
        bsStyle="danger"
        destroyAction={() => destroyAction(id)}
        link
        title="Destroy Address"
      />
      <DisconnectButton
        display={!!displayDisconnect}
        icon="scissors"
        bsStyle="muted"
        disconnectAction={() => disconnectAction(id)}
        link
        title="Cut connection to transaction"
      />
      {streetAddress && <br />}
      <span title={`County: ${county}`}>
        {city}, {state} {zipcode}
      </span>
      {mlsNumber && <br />}
      {mlsNumber && (
        <span>
          <label>MLS#:</label> {mlsNumber}
        </span>
      )}
      <div style={{ display: "block" }}>
        {primary && (
          <span alt="Primary" title="Primary">
            {" "}
            <FontAwesomeIcon icon="star" className="text-warning" />
          </span>
        )}{" "}
        <AddressTypeBlock icon={val.icon} name={val.name} />
      </div>
    </div>
  );
};
AddressBlock.propTypes = {
  zipcode: PropTypes.string,
  addressType: PropTypes.string,
  primary: PropTypes.bool,
  state: PropTypes.string,
  city: PropTypes.string,
  streetAddress: PropTypes.string,
  county: PropTypes.string
};
AddressBlock.defaults = {
  zipcode: "",
  county: "",
  addressType: "",
  primary: false,
  state: "",
  city: "",
  highlightStreet: true
};
export default AddressBlock;
