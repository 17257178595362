import React, { Component } from "react";

import { Button as BSButton } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import ErrorFromButton from "components/shared/ErrorFromButton";

export default class Button extends Component {
  state = {
    errors: null,
    focused: null,
    hover: null
  };
  onFocus = () => {
    this.setState({ focused: true });
  };
  onBlur = () => {
    this.setState({ focused: false });
  };
  hoverOn = () => {
    this.setState({ hover: true });
  };
  hoverOff = () => {
    this.setState({ hover: false });
  };
  clickWrapper = e => {
    Promise.resolve(this.props.onClick(e)).catch(error => {
      this.setState({ errors: error });
    });
  };
  render() {
    const {
      display,
      children,
      fill,
      icon,
      iconEl,
      color,
      size,
      text,
      to,
      link,
      round,
      goBack,
      styleOpts,
      externalTo,
      className,
      onClick,
      newWindow,
      pullRight,
      ...rest
    } = this.props;
    const { focused, hover } = this.state;
    // console.log("Errors for button", this.state.errors)
    if (display !== undefined && display !== true) {
      return null;
    }

    const inner_content = (
      <span>
        {icon && <FontAwesomeIcon icon={icon} />} {iconEl} {text}
      </span>
    );

    let current_class_names = [];
    if (className) {
      current_class_names.push(className);
    }
    if (link) {
      current_class_names.push("btn-simple");
    }
    if (round) {
      current_class_names.push("btn-round");
    }
    if (pullRight) {
      current_class_names.push("pull-right");
    }
    if (focused || hover) {
      if (fill) {
        //Dummy
      } else {
        current_class_names.push("btn-fill");
      }
    } else if (fill) {
      current_class_names.push("btn-fill");
    }
    let finalStyleOpts = { ...styleOpts } || {};

    finalStyleOpts.zIndex = 100;
    finalStyleOpts.position = "relative";
    const btn = (
      <BSButton
        {...rest}
        onClick={onClick ? this.clickWrapper : null}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        onMouseEnter={this.hoverOn}
        onMouseLeave={this.hoverOff}
        bsStyle={color || "primary"}
        bsSize={size || null}
        className={classnames(current_class_names)}
        style={finalStyleOpts}
      >
        <ErrorFromButton errors={this.state.errors} />
        {inner_content}
        {children}
      </BSButton>
    );
    if (externalTo) {
      return (
        <a href={externalTo} target={newWindow ? "_blank" : null}>
          {btn}
        </a>
      );
    } else if (to) {
      return (
        <Link tabIndex="-1" to={to}>
          {btn}
        </Link>
      );
    } else if (goBack) {
      return (
        <a
          tabIndex="-1"
          onClick={() => {
            goBack.goBack();
          }}
        >
          {btn}
        </a>
      );
    }

    return btn;
  }
}
