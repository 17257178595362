import React from "react";

import ServerError from "./ServerError";

const ErrorWithButton = ({ errors }) => {
  if (errors === null) {
    return null;
  }

  return <ServerError errors={errors} inline />;
};

export default ErrorWithButton;
