import { take, call, put, select } from "redux-saga/effects";

import AT from "../constants/ActionTypes";

import AppConfig from "../config/AppConfig";
import AuthenticationService from "../services/authentication_service";

export default function* process_login(action) {
  console.log("process_login Action", action);

  try {
    //  console.log("Marking Pending");
    yield put({ type: AT.PENDING_LOGIN_FOR_USER });
    const tokens = yield call(
      AuthenticationService.processLogin,
      action.payload.url_hash
    );
    // console.log(action.payload.url_hash);
    // console.log("Tokens are", tokens);
    yield put({
      type: AT.LOG_IN_USER,

      meta: {},
      payload: tokens
    });
    yield put({
      type: AT.SCHEDULE_TOKEN_RENEWAL,
      meta: {},
      payload: { expiresAt: parseInt(tokens.expires_at) }
    });
    //action.payload.history.push("/");
    window.location = AppConfig.appUrl;

    yield put({ type: AT.SERVICE_LOGIN_DONE });
  } catch (error) {
    //  yield put({ type: AT.SERVICE_LOGIN_FAILURE, payload: error });
    yield put({ type: AT.SERVICE_LOGIN_DONE });

    if (action && action.meta && action.meta.reject) {
      action.meta.reject(error);
    }
  }
}
