import { createSelector } from "reselect";

const breadcrumbSelector = state => state.breadcrumb.crumbs;
const accountIdSelector = state => state.breadcrumb.accountId;
export default createSelector(
  [breadcrumbSelector, accountIdSelector],
  (crumbs, accountId) => {
    const brokerageTransactionList = [];
    const commissionSchedulesList = [];
    const reportResultsList = [];

    if (crumbs && crumbs[accountId]) {
      if (crumbs[accountId].hasOwnProperty("BrokerageTransaction")) {
        crumbs[accountId].BrokerageTransaction.map(key => {
          brokerageTransactionList.push(crumbs.dict[key]);
        });
      }
      if (crumbs[accountId].hasOwnProperty("CommissionSchedule")) {
        crumbs[accountId].CommissionSchedule.map(key => {
          commissionSchedulesList.push(crumbs.dict[key]);
        });
      }
      if (crumbs[accountId].hasOwnProperty("ReportResult")) {
        crumbs[accountId].ReportResult.map(key => {
          reportResultsList.push(crumbs.dict[key]);
        });
      }
    }
    return {
      brokerageTransactions: brokerageTransactionList,
      commissionSchedules: commissionSchedulesList,
      reportResults: reportResultsList
    };
  }
);
