import { createSelector } from "reselect";

const accessTokenSelector = state => state.settings.access_token;

export default createSelector([accessTokenSelector], access_token => {
  if (access_token) {
    return true;
  } else {
    return false;
  }
});
