import { takeEvery } from "redux-saga/effects";
import action_navigate from "./action_navigate";
import app_initialize from "./app_initialize";
import location_change from "./location_change";
import redux_monitor from "./redux_monitor";
import service_login from "./service_login";
import logout_cleanup from "./logout_cleanup";
import process_login from "./process_login";
import check_already_logged_in from "./check_already_logged_in";
import renew_token from "./renew_token";
import schedule_token_renewal from "./schedule_token_renewal";


import AT from "../constants/ActionTypes";

export default function* rootSaga() {
  // yield takeEvery("*", redux_monitor);
  yield takeEvery([AT.ACTION_NAVIGATE], action_navigate);
  yield takeEvery(AT.APP_INITIALIZE, app_initialize);
  yield takeEvery("@@router/LOCATION_CHANGE", location_change);
  yield takeEvery([AT.LOG_OUT_USER], logout_cleanup);
  yield takeEvery([AT.SERVICE_LOGIN], service_login);
  yield takeEvery([AT.PROCESS_LOGIN], process_login);
  yield takeEvery([AT.CHECK_ALREADY_LOGGED_IN], check_already_logged_in);
  yield takeEvery([AT.RENEW_TOKEN], renew_token);
  yield takeEvery([AT.SCHEDULE_TOKEN_RENEWAL], schedule_token_renewal);
}
