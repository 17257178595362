import React, { Component } from "react";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Alert } from "react-bootstrap";
import { Button } from "components/shared";

export default class LoadingReport extends Component {
  render() {
    const { className, children, reportResultId } = this.props;
    return (
      <Alert bsStyle="info">
        <FontAwesomeIcon
          icon="spinner"
          spin
          pulse
          size="lg"
          fixedWidth
          color=""
        />{" "}
        Report is being printed to the screen.
        {reportResultId && (
          <span>
            If page gets stuck{" "}
            <Button
              to={`/report_results/${reportResultId}`}
              title="Jump To Report"
              icon="chart-pie"
              bsSize="small"
            />
          </span>
        )}
      </Alert>
    );
  }
}
