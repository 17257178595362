import React, { Component } from "react";
import ui from "redux-ui";

import {
  Button,
  ButtonGroup,
  Col,
  ControlLabel,
  FormGroup
} from "react-bootstrap";

import ItemChoice from "./ItemChoice";
import ItemChosen from "./ItemChosen";

@ui({})
export default class FieldItemsChoiceSelect extends Component {
  static propTypes = {};
  static defaultProps = {};
  state = { allChosen: false };

  componentWillReceiveProps(nextProps) {
    if (
      (this.props.ui.chosenDetails === null ||
        this.props.ui.chosenDetails === []) &&
      !nextProps.ui.wipeDetail &&
      nextProps.chosenDetails
    ) {
      this.chooseDetail(nextProps.chosenDetails);
    }
    if (nextProps.overrideChosenDetail) {
      this.chooseDetail(nextProps.overrideChosenDetail);
    }
  }
  chooseDetail = item => {
    const {
      ui: { chosenDetails }
    } = this.props;

    const newChosenDetails = [...chosenDetails];
    newChosenDetails.push(item);

    this.updateChosenDetails(newChosenDetails);
  };
  clearValue = clearedDetail => {
    const {
      updateUI,

      getDetailId,
      ui: { chosenDetails }
    } = this.props;
    updateUI("wipeDetail", true);

    const newChosenDetails = [...chosenDetails];
    const clearDetailId = getDetailId
      ? getDetailId(clearedDetail)
      : clearedDetail.id;

    const idIdx = newChosenDetails.findIndex(item => {
      const itemId = getDetailId ? getDetailId(item) : item.id;
      return itemId === clearDetailId;
    });
    if (idIdx > -1) {
      newChosenDetails.splice(idIdx, 1);
    }
    this.updateChosenDetails(newChosenDetails);
    this.setState({ allChosen: false });
  };
  selectAll = () => {
    const { edges } = this.props;

    const items = edges.map(({ node: item }) => item);
    this.updateChosenDetails(items);
  };
  deselectAll = () => {
    this.updateChosenDetails([]);
    this.setState({ allChosen: false });
  };
  updateChosenDetails = newChosenDetails => {
    const { input, updateUI, syncSelected, edges } = this.props;
    updateUI("wipeDetail", true);

    const ids = newChosenDetails.map(detail => this.resolveDetailId(detail));

    input.onChange(ids);
    if (ids.length === edges.length) {
      this.setState({ allChosen: true });
    }

    updateUI("chosenDetails", newChosenDetails);
    if (syncSelected) {
      syncSelected(newChosenDetails);
    }
  };

  resolveDetailId = item => {
    const { getDetailId } = this.props;
    return getDetailId ? getDetailId(item) : item.id;
  };
  renderSelect = () => {
    const {
      loading,
      edges,
      itemChoiceDetail,
      ui: { chosenDetails }
    } = this.props;
    if (loading) {
      return null;
    }
    const chosenIds = chosenDetails.map(detail => this.resolveDetailId(detail));
    let itemChoices = edges.map(
      ({ node: item }) =>
        chosenIds.includes(this.resolveDetailId(item)) ? null : item
    );
    itemChoices = itemChoices.filter(item => item);

    return (
      <div>
        <FormGroup>
          <Col mdOffset={3} sm={9} style={{ marginBottom: "6px" }}>
            <ButtonGroup vertical block>
              {!loading &&
                itemChoices.map(item => (
                  <ItemChoice
                    key={item.id}
                    item={item}
                    choose={this.chooseDetail}
                    itemChoiceDetail={itemChoiceDetail}
                  />
                ))}
            </ButtonGroup>
          </Col>
        </FormGroup>
      </div>
    );
  };
  render() {
    const {
      input,
      hint,
      meta,
      choiceLabel,
      selectChoiceLabel,
      chosenSectionTitle = "",
      choiceSectionTitle = "",
      ui: { chosenDetails },
      chosenItemDetail
    } = this.props;
    const { allChosen } = this.state;
    return (
      <div>
        <FormGroup controlId={`form-${input.name}`}>
          {selectChoiceLabel ? (
            <Col componentClass={ControlLabel} sm={3}>
              {selectChoiceLabel}
            </Col>
          ) : (
            <Col componentClass={ControlLabel} sm={3} />
          )}
          <Col sm={9}>
            <h4>{chosenSectionTitle}</h4>
            {chosenDetails.map(chosenDetail => (
              <ItemChosen
                key={this.resolveDetailId(chosenDetail)}
                value={input.value}
                detail={chosenDetail}
                chosenItemDetail={chosenItemDetail}
                clearValue={() => this.clearValue(chosenDetail)}
                choiceLabel={choiceLabel}
              />
            ))}
            {hint && <span className="text-info">{hint}</span>}
            {meta.touched &&
              meta.error && <span className="text-danger">{meta.error}</span>}
          </Col>
        </FormGroup>
        <hr />
        <Col smOffset={3} sm={9}>
          {!allChosen && (
            <Button
              bsSize="small"
              onClick={this.selectAll}
              style={{ marginBottom: "6px" }}
            >
              Select All
            </Button>
          )}
          {allChosen && (
            <Button bsSize="small" onClick={this.deselectAll}>
              Unselect All
            </Button>
          )}
        </Col>
        <h4>{choiceSectionTitle}</h4>
        {this.renderSelect()}
      </div>
    );
  }
}
