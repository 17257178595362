import React from "react";
import { graphql } from "react-apollo";
import { loader as graphqlLoader } from "graphql.macro";

const PhoneNumberTypeListQL = graphqlLoader(
  "src/graphql/queries/PhoneNumberTypeList.graphql"
);
import FieldEnumSelect from "./FieldEnumSelect";

export default graphql(PhoneNumberTypeListQL)(FieldEnumSelect);
