import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import timer from "battery-friendly-timer";
import AppConfig from "config/AppConfig";
import AuthenticationService from "services/authentication_service";

import { Modal } from "react-bootstrap";
import { Button } from "components/shared/";

//Based on code from - https://marmelab.com/blog/2016/08/29/auto-reload-spa-on-mobile-setinterval.html
export default class AutoReload extends Component {
  static propTypes = {
    url: PropTypes.string.isRequired,
    tryDelay: PropTypes.number.isRequired,
    forceDelay: PropTypes.number.isRequired
  };

  static defaultProps = {
    url: "/",
    tryDelay: 5 * 60 * 1000, // 5 minutes
    forceDelay: 24 * 60 * 60 * 1000 // 1 day
  };

  state = { codeHasChanged: false, newCode: null, loggedOut: false };

  componentDidMount() {
    const { tryDelay, forceDelay } = this.props;
    this.fetchSource();
    //   console.log("Uding ", tryDelay, "for TryIntrerval");
    this.tryInterval = setInterval(this.fetchSource, tryDelay);
    this.forceInterval = setInterval(this.fetchSource, forceDelay);
  }

  componentWillUnmount() {
    // console.log("Celaring out the interval");
    clearInterval(this.tryInterval);
    clearInterval(this.forceInterval);
  }

  fetchSource = () => {
    //console.log("Triggering fetchSourlce.");
    if (!AuthenticationService.isAuthenticated()) {
      console.log("They are no longer logged in");
      this.setState({ loggedOut: true });
      return null;
    } else {
      this.setState({ loggedOut: false });
    }
    return fetch(`${this.props.url}?lookup=${Date.now()}`)
      .then(response => {
        if (response.status !== 200) {
          throw new Error("offline");
        }

        return response.text();
      })
      .then(data => {
        if (data.trim() !== AppConfig.versionHash) {
          this.setState({ codeHasChanged: true, newCode: data.trim() });
        }
        // console.log(`"${AppConfig.versionHash}"`,`"${data.trim()}"`, data.trim() === AppConfig.versionHash)
        // const { previousHash } = this.state;
        // //  const hash = this.hash(html);
        // //  console.log("Fetching test", eTag);
        // if (!previousHash) {
        //   this.setState({ previousHash: data });
        //   //  console.log("Setting hash for future");
        //   return;
        // }
        // if (previousHash !== data) {
        //       console.log("Change happened");
        //   this.setState({ codeHasChanged: true, previousHash: data });
        // }
      })
      .catch(() => {
        /* do nothing */
      });
  };

  /**
   * Java-like hashCode function for strings
   *
   * taken from http://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript-jquery/7616484#7616484
   */
  hash(str) {
    const len = str.length;
    let hash = 0;
    if (len === 0) return hash;
    let i;
    for (i = 0; i < len; i++) {
      hash = (hash << 5) - hash + str.charCodeAt(i);
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }

  reloadApp = e => {
    e.preventDefault();
    //  console.log("Using reload");
    // window.location = AppConfig.appUrl + "/" + Date.now() + "/dashboard";
    //  window.location.reload(true);
    //window.location.href = `${AppConfig.appUrl}index.html?lookup=${Date.now()}`;
    window.location.reload(true);
    window.location.replace(window.location.origin);
    // window.location.reload(true);
  };

  render() {
    const { codeHasChanged, loggedOut } = this.state;

    if (loggedOut) {
      return <Redirect to="/login" />;
    }
    if (!codeHasChanged) {
      return null;
    }

    return (
      <Modal show={true} bsSize="large">
        <Modal.Header>
          <Modal.Title>
            <FontAwesomeIcon icon="bullhorn" /> Please Update
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p className="text-warning">
            A newer version is available with more features and fixes.
          </p>
        </Modal.Body>

        <Modal.Footer>
          <Button
            onClick={this.reloadApp}
            color="warning"
            icon={["far", "cloud-download"]}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
