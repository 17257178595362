import React from "react";
import Helmet from "react-helmet";
import PropTypes from "prop-types";
import AppConfig from "config/AppConfig";

const MetaTitle = ({ pageTitle, title }) => {
  if (title) {
    console.warn("You passed a title - the variable should be pageTitle");
  }
  return (
    <Helmet>
      <title>{`${`${pageTitle} |` || ""} Brokerage Engine v${
        AppConfig.version
      }`}</title>
      <meta
        name="Brokerage Engine"
        content="The best way to manage your residential real
            estate brokerage!"
      />
    </Helmet>
  );
};
MetaTitle.propTypes = {
  pageTitle: PropTypes.string
};
export default MetaTitle;
