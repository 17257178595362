import React, { Component } from "react";

export default class CardTitle extends Component {
  render() {
    const {
      cardClass,
      title,
      category,
      classes,
      contentClass,
      id,
      content,
      legend,
      stats,
      children,
      statsIcon
    } = this.props;
    return (
      <div className={"cardtitle " + cardClass} style={{ marginBottom: "1em" }}>
        <h3 className="title">{content || children}</h3>
      </div>
    );
  }
}
