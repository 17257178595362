import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Tooltip, OverlayTrigger } from "react-bootstrap";

const tooltip = <Tooltip id="tooltip">Required</Tooltip>;

const Required = ({ isRequired }) => {
  if (isRequired) {
    return (
      <OverlayTrigger
        trigger={["hover", "focus"]}
        placement="right"
        overlay={tooltip}
      >
        <FontAwesomeIcon icon="asterisk" size="sm" className="text-warning" />
      </OverlayTrigger>
    );
  }
  return null;
};

export default Required;
