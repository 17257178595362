/**
 * Convert output panel
 */
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppConfig from "config/AppConfig";

import {
  Col,
  Grid,
  OverlayTrigger,
  Row,
  Table,
  Tooltip
} from "react-bootstrap";

import { Panel, PanelBody, PanelFooter, PanelHeader } from "components/shared";

const GiphyRandom = require("giphy-random");

const giphyRandom = new GiphyRandom({
  apiKey: AppConfig.giphyApiKey,
  rating: "PG"
});

// Based on https://gist.githubusercontent.com/samuel-holt/388acdecbff5e4cf50f35a6e3dcbb638/raw/7f5b0692340383fda4c9802537e77103c0b1e432/errorBoundary.jsx

// Stateless error panel

export default class ErrorPanel extends React.Component {
  state = { giphyImage: null };
  componentDidMount() {
    giphyRandom.get({ tag: "fail trick epic" }).then(data => {
      //   console.log(data);
      this.setState({ giphyImage: data.image_url });
    });
  }
  render() {
    const { currentError, errorInfo, debugMode, data } = this.props;
    const { giphyImage } = this.state;
    console.log("data", data);
    let debugMessage = "";
    if (errorInfo && debugMode) {
      // Verbose output for local testing:
      debugMessage = (
        <div className="local-dev-debug card danger">
          <h5>Error message:</h5>
          <pre>
            <code>{currentError.message}</code>
          </pre>
          <h5>Error stack:</h5>
          <pre>
            <code>{currentError.stack}</code>
          </pre>
          <h5>Component stack:</h5>
          <pre>
            <code>{errorInfo.componentStack}</code>
          </pre>
        </div>
      );
    }

    return (
      <div style={{ margin: "4rem" }}>
        <Panel>
          <PanelHeader style={{ backgroundColor: "red" }}>
            <h2 style={{ color: "white" }}>
              <FontAwesomeIcon icon="exclamation-triangle" /> System Error
            </h2>
          </PanelHeader>
          <PanelBody>
            <Grid fluid>
              <Row>
                <Col md={8}>
                  <p style={{ fontSize: "1.8em" }}>
                    The system has encountered an error. Our Technical Team has
                    been notified to investigate.
                  </p>
                  <p style={{ fontSize: "1.6em" }}>
                    If you would like us to follow-up with you once the problem
                    is resolved, please:
                  </p>
                  <ol style={{ fontSize: "1.6em" }}>
                    <li>
                      Click the{" "}
                      <FontAwesomeIcon
                        icon="question-circle"
                        style={{ color: "#39cfef" }}
                      />{" "}
                      in the lower right corner.
                    </li>
                    <li>
                      Complete the form to contact us with a brief description
                      of what you were doing when this error happened.
                    </li>
                  </ol>
                </Col>
                <Col md={4}>
                  <div style={{ paddingLeft: "4em", paddingBottom: "6em" }}>
                    <p>{debugMessage}</p>
                  </div>
                </Col>
              </Row>
            </Grid>
          </PanelBody>
        </Panel>
      </div>
    );
  }
}
