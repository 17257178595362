import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ButtonGroup,
  Button,
  ButtonToolbar,
  Form,
  InputGroup,
  FormControl
} from "react-bootstrap";

import ui from "redux-ui";

const MaxButton = ({ currentValue, value, clickFn }) => (
  <Button
    onClick={() => clickFn(value)}
    className={currentValue === value ? "btn-primary" : ""}
  >
    {value}
  </Button>
);

@ui({
  state: {}
})
export default class SortableHeader extends Component {
  changeOrderDirection = () => {
    const finder = Object.assign({}, this.props.ui.finder);
    if (finder.orderDirection === "ASC") {
      finder.orderDirection = "DESC";
      finder.iconDirection = "down";
    } else {
      finder.orderDirection = "ASC";
      finder.iconDirection = "up";
    }
    finder.afterCursor = null;
    finder.beforeCursor = null;
    this.props.updateUI("finder", finder);
    this.props.loadMore(finder);
  };
  changeOrderBy = () => {
    const finder = Object.assign({}, this.props.ui.finder);
    finder.orderBy = this.props.name;
    finder.afterCursor = null;
    finder.beforeCursor = null;

    finder.orderDirection = "ASC";
    finder.iconDirection = "up";

    this.props.updateUI("finder", finder);
    this.props.loadMore(finder);
  };
  render() {
    const {
      ui: {
        finder: { orderBy, orderDirection, iconDirection }
      },
      name,
      icon,
      colSpan,
      children
    } = this.props;

    if (orderBy === name) {
      return (
        <th onClick={this.changeOrderDirection} colSpan={colSpan}>
          <FontAwesomeIcon icon={icon} /> {children}{" "}
          <FontAwesomeIcon icon={`sort-amount-${iconDirection || "down"}`} />
        </th>
      );
    }
    return (
      <th onClick={this.changeOrderBy} colSpan={colSpan}>
        <FontAwesomeIcon icon={icon} /> {children}
      </th>
    );
  }
}
