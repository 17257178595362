import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FileViewer from "react-file-viewer";

import { ButtonToolbar } from "react-bootstrap";
import {
  Button,
  Card,
  CardTitle,
  DestroyButton,
  NameVal,
  Page,
  PdfDisplay,
  Tags
} from "components/shared";

// {fileUrl && (
//      <FileViewer
//        fileType={this.fileViewerType(businessDocument.contentType)}
//        filePath={fileUrl}
//      />
//    )}

export default class FileDisplay extends Component {
  static propTypes = {};
  static defaultProps = {};

  fileViewerType = contentType => {
    if (contentType.match("png")) {
      return "png";
    } else if (contentType.match("jpg")) {
      return "jpg";
    } else if (contentType.match("pdf")) {
      return "pdf";
    } else if (contentType.match("xls") || contentType.match("spreadsheet")) {
      return null;
    } else if (contentType.match("csv")) {
      return "csv";
    } else if (
      contentType.match("doc") ||
      contentType.match("wordprocessing")
    ) {
      return "docx";
    }
    return null;
  };
  render() {
    const { fileUrl, contentType } = this.props;

    if (!fileUrl || !this.fileViewerType(contentType)) {
      return null;
    }
    if (contentType === "application/pdf") {
      return <PdfDisplay pdf={fileUrl} />;
    }
    return (
      <FileViewer
        fileType={this.fileViewerType(contentType)}
        filePath={fileUrl}
      />
    );
  }
}
