import React, { Component } from "react";
import ui from "redux-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  FormGroup,
  Col,
  ControlLabel,
  FormControl,
  InputGroup,
  ButtonGroup
} from "react-bootstrap";

import { Button, ItemChoice, ItemChosen, Required } from "components/shared";

// @ui({
//   state: {
//     currentSearchText: "",
//     chosenItem: null,
//     search: {
//       max: 5,
//       searchText: "",
//       toVar: function() {
//         return {
//           searchText: this.searchText,
//           first: this.max
//         };
//       }
//     }
//   },
//   persist: true
// })

@ui({})
export default class FieldItemChoose extends Component {
  static propTypes = {};
  static defaultProps = {};
  hasASelectStep = () => !!this.props.renderSelectItem;
  updateSearchText = evt => {
    this.props.updateUI("currentSearchText", evt.target.value);
  };
  clearValue = () => {
    const { input, updateUI, syncChosen } = this.props;
    updateUI("wipeItem", true);
    updateUI("chosenItem", null);
    if (syncChosen) {
      syncChosen(null);
    }
    input.onChange(null);
  };
  clearSearch = () => {
    const search = Object.assign({}, this.props.ui.search);
    search.searchText = null;
    this.props.updateUI("currentSearchText", "");
    this.props.updateUI("search", search);
  };
  updateSearch = evt => {
    const search = Object.assign({}, this.props.ui.search);
    search.searchText = this.props.ui.currentSearchText;
    this.props.updateUI("search", search);

    evt.preventDefault();

    this.props.loadMoreEntries(search);
  };
  chooseItem = item => {
    const { input, updateUI, syncChosen } = this.props;
    this.clearSearch();
    if (this.hasASelectStep()) {
      input.onChange(null);
    } else {
      input.onChange(item.id);
    }
    updateUI("chosenItem", item);
    if (syncChosen) {
      syncChosen(item);
    }
  };
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.ui.chosenItem === null &&
      !nextProps.ui.wipeItem &&
      nextProps.chosenItem
    ) {
      // console.log("Going to set item", nextProps.chosenItem);
      this.chooseItem(nextProps.chosenItem);
    }
  }
  onKeyDown = event => {
    const { keyCode } = event;
    switch (keyCode) {
      case 9: // TAB
      case 13: // ENTER
        event.stopPropagation();
        this.updateSearch(event);
        break;
      case 27: // escape
        event.preventDefault();
        event.stopPropagation();
        this.clearSearch();
        break;
    }
  };

  renderSearch = () => {
    const {
      input,
      loading,
      edges,
      itemChoiceDetail,
      choiceLabel,
      ui: { currentSearchText, chosenItem }
    } = this.props;
    if (chosenItem) {
      return null;
    }
    return (
      <div>
        <FormGroup controlId={`form-${input.name}-search`}>
          <Col componentClass={ControlLabel} sm={3} />
          <Col mdOffset={3} sm={8} style={{ marginBottom: "6px" }}>
            <InputGroup style={{ zIndex: 0 }}>
              <FormControl
                type="text"
                value={currentSearchText}
                onChange={this.updateSearchText}
                onKeyDown={this.onKeyDown}
              />
              <InputGroup.Button>
                <Button
                  onClick={this.clearSearch}
                  icon="times"
                  color="default"
                />
                <Button
                  onClick={this.updateSearch}
                  icon="search"
                  text="Search"
                />
              </InputGroup.Button>
            </InputGroup>
          </Col>
        </FormGroup>
        <FormGroup>
          <Col componentClass={ControlLabel} sm={3}>
            {choiceLabel}
          </Col>
          <Col sm={9} style={{ marginBottom: "6px" }}>
            <ButtonGroup vertical block>
              {loading && (
                <span>
                  <FontAwesomeIcon
                    icon="spinner"
                    spin
                    pulse
                    className="text-muted"
                  />
                </span>
              )}
              {!loading &&
                edges.length === 0 && (
                  <div>
                    <span className="fa-lg">
                      <span className="fa-layers fa-fw">
                        <FontAwesomeIcon
                          icon={["far", "search"]}
                          transform="shrink-7 flip-h"
                          className=""
                        />

                        <FontAwesomeIcon
                          icon={["far", "ban"]}
                          className="text-danger"
                        />
                      </span>
                    </span>
                    <span className="text-danger">
                      {" "}
                      Sorry, we couldn't find any results.
                    </span>
                  </div>
                )}
              {!loading &&
                edges.map(({ node: item }) => (
                  <ItemChoice
                    key={item.id}
                    item={item}
                    choose={this.chooseItem}
                    itemChoiceDetail={itemChoiceDetail}
                  />
                ))}
            </ButtonGroup>
          </Col>
        </FormGroup>
      </div>
    );
  };
  render() {
    const {
      input,
      required,
      hint,
      meta,
      label,
      chosenItemDetail,
      choiceLabel,
      ui: { chosenItem }
    } = this.props;

    return (
      <div>
        <FormGroup controlId={`form-${input.name}`}>
          <Col componentClass={ControlLabel} sm={3}>
            {label}{" "}
            {required && (
              <span>
                <Required isRequired />
              </span>
            )}
          </Col>
          <Col sm={9} style={{ marginBottom: "6px" }}>
            <ItemChosen
              value={chosenItem ? chosenItem.id : null}
              detail={chosenItem}
              chosenItemDetail={chosenItemDetail}
              clearValue={this.clearValue}
              choiceLabel={choiceLabel}
            />

            {hint && <span className="text-info">{hint}</span>}

            {!this.hasASelectStep() &&
              meta.touched &&
              meta.error && <span className="text-error">{meta.error}</span>}
          </Col>
        </FormGroup>
        {this.renderSearch()}
        {this.hasASelectStep() && this.props.renderSelectItem(chosenItem)}
      </div>
    );
  }
}
