import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import { Tooltip, OverlayTrigger } from "react-bootstrap";

const Tag = ({ name }) => (
  <span
    title={`Tagged as ${name}`}
    style={{
      fontSize: "1.6rem",
      padding: "8px"
    }}
    className="text-warning"
    link="true"
    disabled
  >
    <FontAwesomeIcon
      icon="hashtag"
      className="danger"
      style={{ marginRight: "4px", opacity: 0.7 }}
    />
    {name}
  </span>
);

const Tags = ({ names }) => {
  if (!names) {
    return null;
  }
  return (
    <span>
      {names.map(name => (
        <Tag key={name} name={name} />
      ))}
    </span>
  );
};
Tags.propTypes = {};
Tags.defaults = {};
export default Tags;
