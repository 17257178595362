import React from "react";

import Button from "./Button";

const ItemChoice = ({ item, choose, itemChoiceDetail }) => (
  <Button
    onClick={() => {
      choose(item);
    }}
    onMouseEnter={this.hoverOn}
    onMouseLeave={this.hoverOff}
    color="primary"
    block
    styleOpts={{ textAlign: "left" }}
  >
    {itemChoiceDetail({ item, choose })}
  </Button>
);

export default ItemChoice;
