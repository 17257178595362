import React from "react";
import PropTypes from "prop-types";
export default class PanelBody extends React.Component {
  render() {
    const { display, hidden, onClick, style, children, scrollx } = this.props;
    let currentStyle = Object.assign({}, style);

    if (display !== undefined && display !== true) {
      return null;
    }
    if (hidden) {
      return null;
    }

    if (scrollx) {
      currentStyle.overflowX = "auto";
    }

    return (
      <div className="panel-body" style={currentStyle} onClick={onClick}>
        {children}
      </div>
    );
  }
}

PanelBody.propTypes = {
  hidden: PropTypes.bool
  // lastReply: PropTypes.object.isRequired
};
PanelBody.defaultProps = {
  hidden: false
};
