import React, { Component } from "react";
import PropTypes from "prop-types";

import classnames from "classnames";

import { Button, ServerError } from "components/shared";
import MetaTitle from "./MetaTitle";
import LoadingPage from "./LoadingPage";

export default class Page extends Component {
  static propTypes = {
    pageTitle: PropTypes.string,
    isLoading: PropTypes.bool,
    isStale: PropTypes.bool,
    backTo: PropTypes.string
  };
  static defaultProps = {
    pageTitle: "",
    isLoading: true
  };

  render() {
    const {
      pageTitle,
      isStale,
      isLoading,
      backTo,
      children,
      dataError
    } = this.props;
    if (dataError) {
      return <ServerError errors={dataError} inline={false} />;
    }
    if (isLoading) {
      return <LoadingPage pageTitle={pageTitle} isLoading={isLoading} />;
    }

    const class_names = [];
    const style = {
      paddingTop: "16px"
    };

    if (isStale) {
      class_names.push("bg-warning");
    } else {
      style.backgroundColor = "#f5f5f5";
    }

    return (
      <div style={style} className={classnames(class_names)}>
        {pageTitle && <MetaTitle pageTitle={pageTitle} />}
        {backTo && (
          <span>
            <Button to={backTo} color="primary">
              &lt;&lt;&lt;Back
            </Button>
            <br />
          </span>
        )}
        <div className="container-fluid">{children}</div>
      </div>
    );
  }
}
