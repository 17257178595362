import { put } from "redux-saga/effects";
import { delay } from "redux-saga";
import AT from "../constants/ActionTypes";

export default function* schedule_token_renewal(action) {
  // console.log("Scheudle TOken Reneqal ?Action", action);

  const expiresAt = parseInt(action.payload.expiresAt);
  const delayInMs = Math.max(1, expiresAt - Date.now());
  // console.log("Queueing up the reneal" , delayInMs/1000);
  yield delay(delayInMs);
  yield put({
    type: AT.RENEW_TOKEN,

    meta: {},
    payload: {}
  });
}
