import { take, call, put, select } from "redux-saga/effects";

//import { push } from "react-router-redux";

import AT from "../constants/ActionTypes";

function push() {
  console.log("***************need to add support");
}
export default function* action_navigate(action) {
  // console.log("navigate action", action);
  // console.log("Action is", action.linkAction());
  if (action.payload.linkAction) {
    yield put(action.payload.linkAction());
  }
  // console.log("Push", push(action.payload.to));
  console.log("Redirect to", action.payload.to);
  yield put(push(action.payload.to));
}
