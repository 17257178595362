import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import classnames from "classnames";
import Button from "./Button";
import DestroyButton from "./DestroyButton";

const EmailBlock = ({
  id,
  email,
  emailType,
  primary,
  displayEdit,
  editUrl,
  displayDestroy,
  destroyAction
}) => {
  if (!id) {
    return null;
  }
  const values = {
    WORK: { name: "Work", icon: ["far", "briefcase"] },
    HOME: { name: "Home", icon: "home" },
    OTHER: { name: "Other", icon: ["far", "question-square"] }
  };
  const val = values[emailType] || {
    icon: ["far", "question-square"],
    name: "Unknown"
  };
  return (
    <div className="email-block">
      <span>
        <b>
          <a href={"mailto:" + email}> {email} </a>
        </b>
      </span>
      <div style={{ display: "block" }}>
        {primary && (
          <span alt="Primary" title="Primary">
            {" "}
            <FontAwesomeIcon icon="star" className="text-warning" />
          </span>
        )}{" "}
        <span className="text-muted" style={{ marginLeft: "10px" }}>
          <FontAwesomeIcon icon={["far", "envelope"]} />{" "}
          <FontAwesomeIcon icon={val.icon} /> {val.name}
        </span>
        <Button
          display={displayEdit ? true : false}
          text={""}
          icon={"pencil-alt"}
          to={editUrl}
          size={"sm"}
          link
          title="Edit Email"
        />
        <DestroyButton
          display={displayDestroy ? true : false}
          icon={"trash-alt"}
          bsStyle={"danger"}
          destroyAction={() => destroyAction(id)}
          size={"sm"}
          link
          title="Destroy Email"
        />
      </div>
    </div>
  );
};
EmailBlock.propTypes = {
  email: PropTypes.string,
  emailType: PropTypes.string,
  primary: PropTypes.bool
};
EmailBlock.defaults = {
  email: "",
  emailType: "",
  primary: false
};
export default EmailBlock;
