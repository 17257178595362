import React, { Component } from "react";

export default class CardBody extends Component {
  render() {
    const {
      cardClass,
      classes,
      contentClass,
      id,
      content,
      children
    } = this.props;
    return (
      <div className={"cardbody" + cardClass} style={{ padding: "2em" }}>
        {content || children}
      </div>
    );
  }
}
