import React from "react";
import ui from "redux-ui";
import { Field } from "redux-form";
import {
  FormGroup,
  Col,
  ControlLabel,
  ToggleButtonGroup
} from "react-bootstrap";

import {
  FieldInput,
  FieldSelect,
  ToggleButton,
  SimpleForm
} from "components/shared";

@ui({})
export default class FieldFeeType extends React.Component {
  changeFeeType = data => {
    this.props.updateUI(this.uiField(), data);

    if (data === "FLAT") {
      this.props.change(this.amountPercentageLabel(), null);
      this.props.change(this.amountExpenseSourceLabel(), null);
    } else {
      this.props.change(this.amountLabel(), null);
    }
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.ui[this.uiField()] === null) {
      if (nextProps.node && nextProps.node.percentageItem) {
        this.props.updateUI(this.uiField(), "PERCENTAGE");
      } else {
        this.props.updateUI(this.uiField(), "FLAT");
      }
    }
  }
  buildAmountExpenseSourceOptions() {
    const options = [];

    const chargeTo = this.props.chargeTo ? this.props.chargeTo : "";
    if (chargeTo && chargeTo !== "" && this.props.amountExpenseSourceOptions) {
      const blob = this.props.amountExpenseSourceOptions;
      const parsedOptions = JSON.parse(blob);
      const opts = parsedOptions[chargeTo];
      opts.map(expType => {
        options.push({
          name: expType,
          value: expType,
          disabled: false
        });
      });
    }

    return options;
  }
  uiField = () => this.props.uiField || "feeType";
  percentageItemLabel = () =>
    this.props.percentageItemLabel || "percentageItem";
  amountLabel = () => this.props.amountLabel || "amount";
  amountPercentageLabel = () =>
    this.props.amountPercentageLabel || "amountPercentage";
  amountExpenseSourceLabel = () =>
    this.props.amountExpenseSourceLabel || "amountExpenseSource";

  render() {
    const { ui, flatLabel, percentageLabel, optional } = this.props;
    const feeType = ui[this.uiField()];
    const amountExpenseSourceOptions = this.buildAmountExpenseSourceOptions();
    return (
      <div>
        <FormGroup>
          <Col componentClass={ControlLabel} sm={3}>
            <ToggleButtonGroup
              name={this.uiField()}
              type="radio"
              value={feeType}
              onChange={this.changeFeeType}
            >
              <ToggleButton value="FLAT" bsStyle="primary">
                Flat Fee
              </ToggleButton>
              <ToggleButton value="PERCENTAGE" bsStyle="primary">
                Percentage Fee
              </ToggleButton>
            </ToggleButtonGroup>
          </Col>

          <Col sm={9}>
            {feeType === "FLAT" && (
              <Field
                currency
                required={!optional}
                label={flatLabel || "Amount"}
                validate={!optional ? SimpleForm.required : null}
                name={this.amountLabel()}
                type="text"
                hint="Postive will debit and Negatives will credit."
                component={FieldInput}
              />
            )}
            {feeType === "PERCENTAGE" && (
              <Field
                name={this.amountPercentageLabel()}
                percent
                required={!optional}
                label={percentageLabel || "% Rate for Fee"}
                validate={!optional ? SimpleForm.required : null}
                type="text"
                hint="This % will be applied to the gross amount of the Payor"
                component={FieldInput}
              />
            )}
            {feeType === "PERCENTAGE" &&
              amountExpenseSourceOptions &&
              amountExpenseSourceOptions.length > 0 && (
                <Field
                  name={this.amountExpenseSourceLabel()}
                  required={!optional}
                  validate={!optional ? SimpleForm.required : null}
                  label="Where should the % be calculated from?"
                  options={amountExpenseSourceOptions}
                  component={FieldSelect}
                />
              )}
          </Col>
        </FormGroup>
      </div>
    );
  }
}
