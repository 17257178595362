import React from "react";
import { graphql } from "react-apollo";
import { loader as graphqlLoader } from "graphql.macro";

const EmailTypeListQL = graphqlLoader(
  "src/graphql/queries/EmailTypeList.graphql"
);
import FieldEnumSelect from "./FieldEnumSelect";

export default graphql(EmailTypeListQL)(FieldEnumSelect);
