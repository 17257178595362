/* eslint-disable no-param-reassign */

// ----------------------
// IMPORTS

import React from "react";
import PropTypes from "prop-types";

import { Route, Redirect as ReactRouterRedirect } from "react-router-dom";

// ----------------------

// <Status code="xxx"> component.  Updates the context router's context, which
// can be used by the server handler to respond to the status on the server.
const Status = props => {
  const { code, children } = props;

  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) {
          staticContext.status = code;
        }
        return children;
      }}
    />
  );
};

// <NotFound> component.  If this renders on the server in development mode,
// it will attempt to proxyify the request to the upstream `webpack-dev-server`.
// In production, it will issue a hard 404 and render.  In the browser, it will
// simply render.
export const NotFound = props => {
  const { children } = props;

  return <Status code={404}>{children}</Status>;
};

// <Redirect> component. Mirrors React Router's component by the same name,
// except it sets a 301/302 status code for setting server-side HTTP headers.
export class Redirect extends React.PureComponent {
  static propTypes = {
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    from: PropTypes.string,
    push: PropTypes.bool,
    permanent: PropTypes.bool
  };

  static defaultProps = {
    from: null,
    push: false,
    permanent: false
  };

  render() {
    const { to, from, push, permanent } = this.props;
    const code = permanent ? 301 : 302;
    return (
      <Status code={code}>
        <ReactRouterRedirect to={to} from={from} push={push} />
      </Status>
    );
  }
}
