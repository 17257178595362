import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const LoadingIcon = ({ isLoading }) => {
  if (isLoading) {
    return <FontAwesomeIcon icon="spinner" spin pulse className="text-muted" />;
  }
  return null;
};

export default LoadingIcon;
