import AT from "src/constants/ActionTypes";
//import { push } from "react-router-redux";

export default {
  serviceLogin(history, resolve = null, reject = null) {
    return {
      type: AT.SERVICE_LOGIN,
      meta: {
        resolve,
        reject
      },
      payload: {
        history
      }
    };
  },
  checkAlreadyLoggedIn(forceUpdate, resolve = null, reject = null) {
    return {
      type: AT.CHECK_ALREADY_LOGGED_IN,
      meta: {
        resolve,
        reject
      },
      payload: {
        forceUpdate
      }
    };
  },
  processLogin(url_hash, history, resolve = null, reject = null) {
    return {
      type: AT.PROCESS_LOGIN,
      meta: {
        resolve,
        reject
      },
      payload: {
        url_hash,
        history
      }
    };
  },
  logOut() {
    return {
      type: AT.LOG_OUT_USER,
      meta: {},
      payload: {}
    };
  }
};
