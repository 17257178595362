import React, { Component } from "react";
import ui from "redux-ui";
import { FieldSelect, FieldItemsChoiceSelect, Button } from "components/shared";

const EnumChoice = ({ item, choose }) => (
  <div>
    <b>{item.name}</b>
  </div>
);

const EnumChosen = ({ value, detail, clearValue }) => (
  <div>
    <p>
      <b>{detail.name}</b>
      <Button onClick={clearValue} icon="times" color="danger" link />
    </p>
  </div>
);

@ui({
  state: {
    chosenDetail: null,
    chosenDetails: [],
    wipedDetails: [],
    wipeDetail: false
  },
  persist: true
})
export default class FieldEnumSelect extends Component {
  static propTypes = {};
  static defaultProps = {};

  enumToOptions(values, allowedTypes) {
    const options = [];
    values.map(enumVal => {
      const enumReadable = enumVal.name
        .replace("_", " ")
        .replace("_", " ")
        .split(" ")
        .map(s => s.slice(0, 1).toUpperCase() + s.slice(1).toLowerCase())
        .join(" ")
        .replace("Gci", "GCI")
        .replace("Cda", "CDA");
      if (!allowedTypes || allowedTypes.includes(enumVal.name)) {
        options.push({
          name: enumReadable,
          value: enumVal.name,
          disabled: false
        });
      }
    });

    return options;
  }
  enumToNodes(values, allowedTypes) {
    const options = [];
    values.map(enumVal => {
      const enumReadable = enumVal.name
        .replace("_", " ")
        .replace("_", " ")
        .split(" ")
        .map(s => s.slice(0, 1).toUpperCase() + s.slice(1).toLowerCase())
        .join(" ")
        .replace("Gci", "GCI")
        .replace("Cda", "CDA");
      if (!allowedTypes || allowedTypes.includes(enumVal.name)) {
        options.push({
          node: {
            id: enumVal.name,
            name: enumReadable,
            value: enumVal.name,
            disabled: false
          }
        });
      }
    });

    return options;
  }
  chosenItemDetail = detailProps => <EnumChosen {...detailProps} />;
  itemChoiceDetail = detailProps => <EnumChoice {...detailProps} />;

  getName = item => item.name;
  getValue = item => item.value;

  render() {
    const {
      data: { loading, __type: graphEnum },
      allowedTypes,
      multiselect
    } = this.props;

    let enumOptions = [];
    if (!loading) {
      if (multiselect) {
        enumOptions = this.enumToNodes(graphEnum.enumValues, allowedTypes);
      } else {
        enumOptions = this.enumToOptions(graphEnum.enumValues, allowedTypes);
      }
    }

    if (multiselect && !loading) {
      return (
        <FieldItemsChoiceSelect
          loading={loading}
          {...this.props}
          resolveDetailId={this.getValue}
          getDetailId={this.getValue}
          choiceLabel="Choose one or more"
          chosenItemDetail={this.chosenItemDetail}
          itemChoiceDetail={this.itemChoiceDetail}
          edges={enumOptions}
        />
      );
    }
    return <FieldSelect {...this.props} options={enumOptions} />;
  }
}
