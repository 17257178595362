import React, { Component } from "react";

import { FieldSelect } from "components/shared";

export default class FieldMonthSelect extends Component {
  static propTypes = {};
  static defaultProps = {};

  buildOptions() {
    return [
      { name: "January", value: 1, disabled: false },
      { name: "February", value: 2, disabled: false },
      { name: "March", value: 3, disabled: false },
      { name: "April", value: 4, disabled: false },
      { name: "May", value: 5, disabled: false },
      { name: "June", value: 6, disabled: false },
      { name: "July", value: 7, disabled: false },
      { name: "August", value: 8, disabled: false },
      { name: "September", value: 9, disabled: false },
      { name: "October", value: 10, disabled: false },
      { name: "November", value: 11, disabled: false },
      { name: "December", value: 12, disabled: false }
    ];
  }

  render() {
    const options = this.buildOptions();

    return <FieldSelect {...this.props} options={options} />;
  }
}
