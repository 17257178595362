import React, { Component } from "react";

import PropTypes from "prop-types";
import classnames from "classnames";
export default class NavItem extends Component {
  static propTypes = {
    location: PropTypes.object,
    activePath: PropTypes.string
  };
  static defaultProps = {
    location: {},
    activePath: ""
  };
  isActive() {
    if (this.props.location && this.props.location.pathname) {
      return this.props.location.pathname.indexOf(this.props.activePath) > -1
        ? "active"
        : "";
    } else {
      return "";
    }
  }
  render() {
    const { className, children } = this.props;
    return (
      <li className={classnames(this.isActive(), className)}>{children}</li>
    );
  }
}
