export default Object.freeze({
  ACTION_NAVIGATE: "ACTION_NAVIGATE",
  APP_INITIALIZE: "APP_INITIALIZE",
  DONE_SERVICE_CONNECTION_INITIALIZATION:
    "DONE_SERVICE_CONNECTION_INITIALIZATION",
  LOG_IN_USER: "LOG_IN_USER",
  LOG_OUT_USER: "LOG_OUT_USER",
  PROCESS_LOGIN: "PROCESS_LOGIN",
  SERVICE_LOGIN: "SERVICE_LOGIN",
  SERVICE_LOGIN_DONE: "SERVICE_LOGIN_DONE",
  SERVICE_LOGIN_SUCCESS: "SERVICE_LOGIN_SUCCESS",
  START_SERVICE_CONNECTION_INITIALIZATION:
    "START_SERVICE_CONNECTION_INITIALIZATION",
  CHECK_ALREADY_LOGGED_IN: "CHECK_ALREADY_LOGGED_IN",
  SET_CURSOR: "SET_CURSOR",
  PENDING_LOGIN_FOR_USER: "PENDING_LOGIN_FOR_USER",
  ADD_CRUMB: "ADD_CRUMB",
  LOAD_CRUMBS: "LOAD_CRUMBS",
  SCHEDULE_TOKEN_RENEWAL: "SCHEDULE_TOKEN_RENEWAL",
  RENEW_TOKEN: "RENEW_TOKEN",
  LOAD_SELECTED_ACCOUNT: "LOAD_SELECTED_ACCOUNT"
});
