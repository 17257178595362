import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { Grid, Row, Col } from "react-bootstrap";
import AppConfig from "config/AppConfig";
import Callback from "components/login/Callback";
import Logout from "components/login/Logout";
import MetaTitle from "components/shared/MetaTitle";
import NotLoggedInMessage from "../shared/NotLoggedInMessage";

import { Page } from "components/shared";
export default class NotLoggedIn extends Component {
  render() {
    return (
      <Page pageTitle="Sign In" isLoading={false}>
        <Grid fluid>
          <Row>
            <Col xsOffset={3} sm={6}>
              <MetaTitle pageTitle={"Sign In Required"} />

              <Switch>
                <Route
                  path="/callback"
                  render={props => <Callback {...props} />}
                />
                <Route path="/logout" render={props => <Logout {...props} />} />
                <Route component={NotLoggedInMessage} />
              </Switch>
            </Col>
            <Col sm={3}> </Col>
          </Row>
        </Grid>

        <p
          className="copyright"
          style={{
            position: "absolute",
            bottom: "0"
          }}
        >
          &copy; {new Date().getFullYear()}{" "}
          <a href={AppConfig.appUrl}>Brokerage Engine, Inc.</a>, The Best Way To
          Run Your Brokerage
        </p>
      </Page>
    );
  }
}
