import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  Pagination,
  ButtonGroup,
  ButtonToolbar,
  Grid,
  Row,
  Col,
  Form,
  InputGroup,
  FormGroup,
  FormControl,
  ControlLabel,
  Label
} from "react-bootstrap";

import Button from "./Button";
// import { Link } from "react-router-dom";
import ui from "redux-ui";

const MaxButton = ({ currentValue, value, clickFn }) => (
  <Button
    onClick={() => clickFn(value)}
    className={currentValue === value ? "btn-primary" : ""}
  >
    {value}
  </Button>
);

@ui({
  state: {
    currentFilterText: ""
  },
  persist: true
})
export default class Finder extends Component {
  setMax = max => {
    let finder = Object.assign({}, this.props.ui.finder);
    finder.max = max;
    this.props.updateUI("finder", finder);
  };
  gotoStart = () => {
    let finder = Object.assign({}, this.props.ui.finder);
    finder.forward = true;
    finder.afterCursor = null;
    finder.beforeCursor = null;
    this.props.updateUI("finder", finder);
    this.props.loadMore(finder, this.props);
  };
  gotoEnd = () => {
    let finder = Object.assign({}, this.props.ui.finder);
    finder.forward = false;
    finder.afterCursor = null;
    finder.beforeCursor = null;
    this.props.updateUI("finder", finder);
    this.props.loadMore(finder, this.props);
  };
  gotoPrev = () => {
    let finder = Object.assign({}, this.props.ui.finder);
    finder.forward = false;
    finder.afterCursor = null;
    finder.beforeCursor = this.props.startCursor;
    this.props.updateUI("finder", finder);
    this.props.loadMore(finder, this.props);
  };
  gotoNext = () => {
    let finder = Object.assign({}, this.props.ui.finder);
    finder.forward = true;
    finder.afterCursor = this.props.endCursor;
    finder.beforeCursor = null;
    this.props.updateUI("finder", finder);
    this.props.loadMore(finder, this.props);
  };
  updateFilterText = evt => {
    this.props.updateUI("currentFilterText", evt.target.value);
  };
  clearFilter = () => {
    let finder = Object.assign({}, this.props.ui.finder);
    //this.setState({ filterText: null });
    finder.filterText = null;
    this.props.updateUI("finder", finder);
  };
  updateFilter = evt => {
    let finder = Object.assign({}, this.props.ui.finder);

    finder.filterText = this.props.ui.currentFilterText;
    this.props.updateUI("finder", finder);

    evt.preventDefault();
    this.props.loadMore(finder, this.props);
  };
  render() {
    let {
      noFilter,
      ui: {
        currentFilterText,
        finder: { max, filterText }
      }
    } = this.props;
    if (currentFilterText == "") {
      currentFilterText = filterText || "";
    }
    const showFilter = !noFilter;
    return (
      <Grid fluid>
        <Row>
          <Col md={8}>
            <ButtonGroup>
              <Button onClick={this.gotoStart}>
                <FontAwesomeIcon icon="fast-backward" />
              </Button>
              <Button onClick={this.gotoPrev}>
                <FontAwesomeIcon icon="chevron-left" />
              </Button>
              <Button onClick={this.gotoNext}>
                <FontAwesomeIcon icon="chevron-right" />
              </Button>
              <Button onClick={this.gotoEnd}>
                <FontAwesomeIcon icon="fast-forward" />
              </Button>
            </ButtonGroup>

            <ButtonGroup title="Show Quantity" style={{ marginLeft: "12px" }}>
              <MaxButton clickFn={this.setMax} value={5} currentValue={max} />
              <MaxButton clickFn={this.setMax} value={25} currentValue={max} />
              <MaxButton clickFn={this.setMax} value={50} currentValue={max} />
              <MaxButton clickFn={this.setMax} value={100} currentValue={max} />
            </ButtonGroup>
          </Col>

          {showFilter && (
            <Col md={4}>
              <ButtonGroup className="pull-right">
                <Form inline onSubmit={this.updateFilter}>
                  <FormGroup>
                    <InputGroup>
                      <FormControl
                        type="text"
                        value={currentFilterText}
                        onChange={this.updateFilterText}
                      />
                      <InputGroup.Button>
                        <Button
                          onClick={this.clearFilter}
                          icon="times"
                          color="default"
                        />
                        <Button
                          onClick={this.updateFilter}
                          icon="filter"
                          text="Filter"
                        />
                      </InputGroup.Button>
                    </InputGroup>
                  </FormGroup>
                </Form>
              </ButtonGroup>
            </Col>
          )}
        </Row>
      </Grid>
    );
  }
}
