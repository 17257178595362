import React, { Component } from "react";
import classnames from "classnames";
import Nav from "./Nav";

export default class TabBar extends Component {
  render() {
    const { className, children } = this.props;
    return <Nav className={classnames("nav-pills", className)}>{children}</Nav>;
  }
}
