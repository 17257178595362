import { take, call, put, select } from "redux-saga/effects";

//import { push } from "react-router-redux";

import AT from "../constants/ActionTypes";

//import { FU, handleAsyncAction } from "../lib/FU";
//import  PDFActions  from "../actions/PDFActions"
//import BrokeragesActions  from "../actions/BrokeragesActions"
//import BrokersActions  from "../actions/BrokersActions"
//import ContractsActions  from "../actions/ContractsActions"
//import CDAsActions  from "../actions/CDAsActions"

const settingsUser = state => state.settings.user;
const routing = state => state.routing;

export default function* location_change(action) {
  //console.log("Location change for ",action);
  //var logged_in_user = yield select(settingsUser);
  //if (!logged_in_user) {
  //  yield take(AT.DONE_SERVICE_CONNECTION_INITIALIZATION);
  //  logged_in_user = yield select(settingsUser);
  //}
  //if (action.payload.pathname.match("/cdas/([A-Za-z0-9_-]\+)/pdf")) {
  //  var cda_id = action.payload.pathname.match("/cdas/([A-Za-z0-9_-]\+)/pdf")[1];
  //  yield put(PDFActions.fetchCDAPDF(cda_id));
  //}
  //else if (action.payload.pathname.match("/cdas/([A-Za-z0-9_-]\+)")) {
  //  var cda_id = action.payload.pathname.match("/cdas/([A-Za-z0-9_-]\+)")[1];
  //  var router_info = yield select(routing);
  //  console.log("Action", action, router_info);
  //  yield put(CDAsActions.fetchCDAById(cda_id));
  //}
  //else if (action.payload.pathname == "/brokers") {
  //  yield put(BrokersActions.fetchBrokers());
  //}
  //else if (action.payload.pathname == "/contracts") {
  //  yield put(ContractsActions.fetchContracts());
  //}
  //else if (action.payload.pathname == "/dashboard") {
  //  yield put(ContractsActions.fetchContractsWithoutCDAS());
  //}
  //else if (action.payload.pathname == "/cdas") {
  //  yield [
  //    put(ContractsActions.fetchContracts()),
  //    put(CDAsActions.fetchCDAs())
  //  ];
  //}
  //else if (action.payload.pathname.match("/admin")) {
  //
  //  if (!logged_in_user || !logged_in_user.is_admin) {
  //    yield put(push("/dashboard"));
  //  }
  //  else {
  //
  //    if (action.payload.pathname.match("/admin/brokerages/([A-Za-z0-9_-]\+)")) {
  //      var brokerage_id = action.payload.pathname.match("/admin/brokerages/([A-Za-z0-9_-]\+)")[1];
  //
  //      yield [
  //        put(BrokeragesActions.fetchBrokerageById(brokerage_id)),
  //        put(BrokeragesActions.fetchBrokerageUsers(brokerage_id))
  //      ];
  //    }
  //    else if (action.payload.pathname.match("/admin/brokerages")) {
  //      yield put(BrokeragesActions.fetchBrokerages());
  //    }
  //
  //  }
  //}
}
