import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import classnames from "classnames";

const FileTypeIcon = ({ fileType, showName }) => {
  const values = {
    excel: { name: "Microsoft Excel", icon: ["far", "file-excel"] },
    image: { name: "Image", icon: ["far", "file-image"] },
    pdf: { name: "Portable Document Format", icon: ["far", "file-pdf"] },
    word: { name: "Microsoft Word", icon: ["far", "file-word"] }
  };
  const val = values[fileType] || {
    icon: ["far", "file-alt"],
    name: "Unknown"
  };

  return (
    <span className="file-type-icon">
      <FontAwesomeIcon icon={val.icon} /> {showName && val.name}
    </span>
  );
};

export default FileTypeIcon;
