import { call, put } from "redux-saga/effects";

import AT from "../constants/ActionTypes";

import AuthenticationService from "../services/authentication_service";
import AppConfig from "../config/AppConfig";

export default function* renew_token(action) {
  //console.log("Action", action);
  const tokens = yield call(AuthenticationService.renewToken);
  // console.log("Tokens?", tokens);
  yield put({
    type: AT.LOG_IN_USER,
    meta: {},
    payload: tokens
  });
  yield put({
    type: AT.SCHEDULE_TOKEN_RENEWAL,
    meta: {},
    payload: { expiresAt: AuthenticationService.expiresAt() }
  });
}
