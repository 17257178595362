import { call, put } from "redux-saga/effects";

import AT from "../constants/ActionTypes";

import AuthenticationService from "../services/authentication_service";
import AppConfig from "../config/AppConfig";

export default function* check_already_logged_in(action) {
  // console.log("Action", action);

  try {
    // console.log("Triggering Login setup")

    //Figure out if they are already logged in?
    const already_logged_in = AuthenticationService.isAuthenticated();
    if (already_logged_in) {
      // console.log("They are already logged in");
      const tokens = AuthenticationService.fetchAuthTokens();
      // console.log("Result tokens",tokens)
      yield put({
        type: AT.LOG_IN_USER,

        meta: {},
        payload: tokens
      });
      yield put({
        type: AT.LOAD_CRUMBS,
        meta: {},
        payload: {}
      });
      yield put({
        type: AT.SCHEDULE_TOKEN_RENEWAL,
        meta: {},
        payload: { expiresAt: parseInt(tokens.expires_at) }
      });
      //  console.log("Forcing update");
      action.payload.forceUpdate();
      //action.payload.history.push("/");
      //  window.location = AppConfig.appUrl;
      yield put({ type: AT.SERVICE_LOGIN_DONE });
    }
  } catch (error) {
    // yield put({ type: AT.SERVICE_LOGIN_FAILURE, payload: error });
    yield put({ type: AT.SERVICE_LOGIN_DONE });

    if (action && action.meta && action.meta.reject) {
      action.meta.reject(error);
    }
  }
}
