import React from "react";

import ConfirmButton from "./ConfirmButton";

const DeleteButton = props => {
  const { destroyAction, disconnectAction, onClick, ...rest } = props;

  if (onClick) {
    console.warn(
      "Passing an onClick to the delete button is not supported. Please change to destroyAction"
    );
  }
  if (disconnectAction) {
    console.warn(
      "Passing a disconnectAction to the Destroy button is not supported. Please change to destroyAction"
    );
  }
  return (
    <ConfirmButton
      initialIcon={"trash-alt"}
      initialColor={"default"}
      initialText={""}
      actionIcon={"trash-alt"}
      actionText={"Delete"}
      actionColor={"danger"}
      {...rest}
      link
      confirmAction={destroyAction}
    />
  );
};
export default DeleteButton;
