import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormGroup, Col, FormControl, ControlLabel } from "react-bootstrap";
import Select from "react-select";

import { Required } from "components/shared";
import FieldItemsChoiceSelect from "./FieldItemsChoiceSelect";

require("components/styles/css/react-select.global.css");

export default class FieldSelect extends Component {
  static propTypes = {};
  static defaultProps = {};
  updateSelection = newSelectedDetails => {
    const { input, syncSelected } = this.props;
    input.onChange(newSelectedDetails ? newSelectedDetails.value : null);
    if (syncSelected) {
      syncSelected(newSelectedDetails);
    }
  };
  render() {
    const {
      activeFieldList,
      disabledFieldList,
      noSearch,
      noClear,
      label,
      required,
      input,
      hint,
      meta,
      options,
      labelKey
    } = this.props;

    //  console.log("Field is:", field);
    if (activeFieldList) {
      if (!activeFieldList.includes(input.name)) {
        return null;
      }
    }
    if (disabledFieldList) {
      if (disabledFieldList.includes(input.name)) {
        return null;
      }
    }

    const searchable = !noSearch;
    const clearable = !noClear;

    if (label) {
      return (
        <FormGroup controlId={`form-${input.name}`}>
          <Col componentClass={ControlLabel} sm={3}>
            {label}{" "}
            {required && (
              <span>
                <Required isRequired />
              </span>
            )}
          </Col>
          <Col sm={9} style={{ marginBottom: "6px" }}>
            <Select
              {...input}
              value={input.value}
              clearable={clearable}
              searchable={searchable}
              labelKey={labelKey || "name"}
              onChange={this.updateSelection}
              onBlur={() => input.onBlur(input.value)}
              options={options}
            />

            {hint && <span className="text-info">{hint}</span>}
            {meta.touched &&
              meta.error && (
                <span className="text-danger">
                  {" "}
                  <FontAwesomeIcon icon="exclamation-triangle" /> {meta.error}
                </span>
              )}
          </Col>
        </FormGroup>
      );
    }
    return (
      <FormGroup controlId={`form-${input.name}`}>
        <Select
          {...input}
          value={input.value}
          labelKey={labelKey || "name"}
          onChange={this.updateSelection}
          onBlur={() => input.onBlur(input.value)}
          options={options}
          searchable={searchable}
          clearable={clearable}
        />
        {hint && <span className="text-info">{hint}</span>}
        {meta.touched &&
          meta.error && <span className="text-error">{meta.error}</span>}
      </FormGroup>
    );
  }
}
