import { takeEvery, take, call, put, select, delay } from "redux-saga/effects";
//import { push } from "react-router-redux";

import AppConfig from "../config/AppConfig";
import AT from "../constants/ActionTypes";

const settingsAuthToken = state => state.settings.authenticationToken;

export default function* app_initialize(action) {
  //  var auth_token = yield select(settingsAuthToken);
  // console.log("App Init");
  yield put({ type: AT.DONE_SERVICE_CONNECTION_INITIALIZATION });
  return;
  //
  // if (!auth_token) {
  //   //Figure out if they have a cookei for this already
  //
  //   // console.log("Didn't have token - used cookie value", auth_token);
  //   var validation_response;
  //
  //   if (auth_token) {
  //     validation_response = yield call(
  //       AuthenticationService.validateAuthenticationToken,
  //       auth_token
  //     );
  //   }
  //   if (validation_response && validation_response.status == 200) {
  //     //  console.log("Responst", validation_response);
  //     var auth_payload = {
  //       authenticationToken: validation_response.data.authenticationToken,
  //       user: {} // new FUUser(validation_response.data.user)
  //     };
  //     yield put({ type: AT.SET_LOGGED_IN_USER, payload: auth_payload });
  //   } else {
  //     //need to handle login
  //     if (auth_token != "") {
  //       //They have a token set - but it is no longer valid.
  //       yield put({ type: AT.LOG_OUT_USER });
  //     }
  //     yield put(push("/login"));
  //     yield take(AT.SERVICE_LOGIN_SUCCESS);
  //     yield take(AT.SERVICE_LOGIN_DONE);
  //     auth_token = yield select(settingsAuthToken);
  //     //  console.log("Finally have login", auth_token);
  //   }
  // }
  //
  // yield put({ type: AT.START_SERVICE_CONNECTION_INITIALIZATION });
  //
  // yield put({ type: AT.DONE_SERVICE_CONNECTION_INITIALIZATION });
}
