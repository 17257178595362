import React, { Component } from "react";

import { ToggleButton as BSToggleButton } from "react-bootstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";

export default class ToggleButton extends Component {
  state = {
    errors: null,
    focused: null,
    hover: null
  };
  onFocus = () => {
    this.setState({ focused: true });
  };
  onBlur = () => {
    this.setState({ focused: false });
  };
  hoverOn = () => {
    this.setState({ hover: true });
  };
  hoverOff = () => {
    this.setState({ hover: false });
  };
  render() {
    // const {
    //   ...rest
    // } = this.props;
    const { checked, bsStyle, ...rest } = this.props;
    const { focused, hover } = this.state;
    let currentClassNames = [];
    let toggleBSStyle = "primary";
    if (focused || hover) {
      toggleBSStyle = "info";
    }
    if (checked) {
      currentClassNames.push("btn-fill");
    }

    const btn = (
      <BSToggleButton
        className={classnames(currentClassNames)}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        onMouseEnter={this.hoverOn}
        onMouseLeave={this.hoverOff}
        checked={checked}
        {...rest}
        bsStyle={toggleBSStyle}
      />
    );

    return btn;
  }
}
