import React, { Component } from "react";
import classnames from "classnames";

export default class Card extends Component {
  render() {
    const {
      isStale,
      display,
      cardClass,
      title,
      category,
      classes,
      style,
      contentClass,
      id,
      content,
      legend,
      stats,
      children,
      statsIcon
    } = this.props;

    const class_names = [cardClass];

    if (isStale) {
      class_names.push("bg-warning");
    } else {
      class_names.push("bg-white");
    }
    class_names.push("card");
    if (display !== undefined && display !== true) {
      return null;
    }
    return (
      <div className={classnames(class_names)} style={style}>
        <div className="header">
          <h4 className="title">{title}</h4>
          <div className="category">{category}</div>
        </div>
        <div className={`content ${contentClass}`}>
          <div id={id} className={classes}>
            {content || children}
          </div>
          <div className="footer">
            {legend}
            {stats != null ? <hr /> : ""}
            <div className="stats">
              <i className={statsIcon} /> {stats}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
