/* eslint-disable */
import "regenerator-runtime/runtime";

import config from "config/reactql_config";
import "isomorphic-fetch";
import React from "react";

import ReactDOM from "react-dom";
import createBrowserHistory from "history/createBrowserHistory";
import { Router, Route } from "react-router-dom";
import { ApolloProvider } from "react-apollo";
import { Provider } from "react-redux";

import { browserClient } from "lib/apollo";
import createNewStore from "lib/redux";

import LocalStore from "store2";
import createSagaMiddleware from "redux-saga";
import sagas from "sagas";
import "styles.global.css";
import "reducers";
import browserInfo from "browser-info";
import Main from "components/main/Main";
import * as serviceWorker from "serviceWorker";

import Auth0Auth from "lib/auth0_auth";
import AppConfig from "config/AppConfig";
//import brokerageEngineFragmentMatcher from "config/brokerageEngineFragmentMatcher";
import ActionCable from "action-cable-react-jwt";
import Raven from "raven-js";
import { createHttpLink } from "apollo-link-http";
import { ApolloLink } from "apollo-link";
import { InMemoryCache } from "apollo-cache-inmemory";
import ActionCableLink from "graphql-ruby-client/subscriptions/ActionCableLink";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
/* eslint-enable */
console.log(
  "*****LAST DEPLOY ****",
  AppConfig.lastDeploy,
  AppConfig.versionHash
);

const browserClientInfo = browserInfo();
// => {name: 'Chrome', version: '42', fullVersion: '42.1246.0.12', os: 'Linux'}

// console.log(browserClientInfo)
Raven.config(AppConfig.sentryUrl, {
  captureUnhandledRejections: true,
  tags: {
    browser: browserClientInfo.name,
    browserVersion: browserClientInfo.fullVersion,
    os: browserClientInfo.os
  }
}).install();
const auth0 = new Auth0Auth();
/*
const customFetch = (uri, options) => {
  // return fetch('https://jsonplaceholder.typicode.com/posts/1')
  // This reference to the refreshingPromise will let us check later on if we are executing getting the refresh token.
  this.refreshingPromise = null;

  // console.log("before Raven");
  // var result = {};
  //     result.ok = true;
  //     result.status = 200;
  //     result.json = () =>
  //       new Promise(function(resolve, reject) {
  //         resolve({ errors: ["Unknowns"], body: "Token Has Expired" });
  //       });
  //  return result;
  const initialRequest = fetch(uri, options);

  // The apolloHttpLink expects that whatever fetch function is used, it returns a promise.
  // Here we return the initialRequest promise
  return initialRequest.then(response => {
    //console.log("Response", response);
    // if (response.status > 499 && response.status < 600) {
    //   Raven.captureException(error, { extra: info });
    // }
    if (response.status === 401) {
      // response.json().then(result_json => {
      //
      //   console.log("401 - not logged in", result_json);
      //
      // });
      const result = {};
      result.ok = true;
      result.status = 200;
      result.json = () =>
        new Promise((resolve, reject) => {
          resolve({
            data: null,
            loading: false,
            errors: {
              errors: [
                {
                  fields: ["query InitialLogin"],
                  locations: [{ line: 1, column: 4 }],
                  message: "Sorry no login"
                }
              ]
            }
          });
        });
      result.text = () =>
        new Promise((resolve, reject) => {
          resolve(
            JSON.stringify({
              data: null,
              loading: false,
              errors: {
                errors: [
                  {
                    fields: ["query InitialLogin"],
                    locations: [{ line: 1, column: 4 }],
                    message: "Sorry no login"
                  }
                ]
              }
            })
          );
        });
      return result;
    } else if (response.status > 499 && response.status < 600) {
      response.json().then(resultJson => {
        Raven.context(() => {
          Raven.setContext({
            tags: {
              erroryType: "graphql"
            },
            extra: {
              graphql: {
                uri,
                options
              }
            }
          });
        });

        Raven.captureException(new Error("GraphqlError"), (extra: resultJson));
      });
    }
    response.json().then(result_json => {
      console.log("JSON Result", result_json);
    });
    return response;
  });
  // .then(result => {
  //   console.log("Result", result)
  //   if (result.raw.status == 401) {
  //     console.log("Not logged in?")
  //   }
  //   else if (result.raw.status > 499 && result.raw.status < 600) {
  //     console.log("Server Log")
  //   }
  //   // if (result.raw.status > 499 && result.raw.status < 600) {
  //   //   Raven.captureException(result.json);
  //   // }
  //   return result.raw;
  // });
  // .then(json => {
  //   // We should now have the JSON from the response of initialRequest
  //   // We check that we do and look for errors from the GraphQL server
  //   // If it has the error 'User is not logged in' (that's our implementation of a 401) we execute the next steps in the re-auth flow
  //   if (
  //     json &&
  //     json.errors &&
  //     json.errors[0] &&
  //     json.errors[0].message === "User is not logged in."
  //   ) {
  //     if (!this.refreshingPromise) {
  //       // Grab the refresh token from the store
  //       var refresh_token = state.account.loginData.refresh_token;
  //       // Grab the client_id from our config
  //       var client_id = Config.REACT_APP_CLIENT_ID;
  //       // Create the address to grab a new token from
  //       // This endpoint may vary based on your Oauth server
  //       var address =
  //         Config.REACT_APP_SERVER_ADDRESS +
  //         "/o/token/?grant_type=refresh_token&refresh_token=" +
  //         refresh_token +
  //         "&client_id=" +
  //         client_id;
  //
  //       // Execute the re-authorization request and set the promise returned to this.refreshingPromise
  //       this.refreshingPromise = fetch(address, { method: "POST" }).then(
  //         refresh_token_repsonse => {
  //           if (refresh_token_repsonse.ok) {
  //             return refresh_token_repsonse.json().then(refreshJSON => {
  //               // Save the new refresh token to your store or wherever you are keeping it
  //               // saveRefreshToken(refreshJSON.refresh_token)
  //
  //               // Return the new access token as a result of the promise
  //               return refreshJSON.access_token;
  //             });
  //           } else {
  //             // If the re-authorization request fails, handle it here
  //             // You can log user out, or display some sort of session has ended alert
  //             // logUserOut()
  //           }
  //         }
  //       );
  //     }
  //     return this.refreshingPromise.then(newAccessToken => {
  //       // Now that the refreshing promise has been executed, set it to null
  //       this.refreshingPromise = null;
  //
  //       // Set the authorization header on the original options parameter to the new access token we got
  //       options.headers.authorization = `Bearer ${newAccessToken}`;
  //       // Return the promise from the new fetch (which should now have used an active access token)
  //       // If the initialRequest had errors, this fetch that is returned below is the final result.
  //       return fetch(uri, options);
  //     });
  //   }
  //   // If there were no errors in the initialRequest, we need to repackage the promise and return it as the final result.
  //   var result = {};
  //   result.ok = true;
  //   result.json = () =>
  //     new Promise(function(resolve, reject) {
  //       resolve(json);
  //     });
  //   return result;
  // });
};
*/
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});
const httpLink = createHttpLink({
  uri: AppConfig.graphqlUrl,
  // fetch: customFetch,
  // error: errorLink,
  credentials: "include"
});
const cache = new InMemoryCache({
  // /  addTypename: false,
  //fragmentMatcher: brokerageEngineFragmentMatcher,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network"
    }
  }
});

function getAccountIdHeader() {
  const BREADCRUMB_KEY = "accountBreadCrumb";
  const accountSelection = LocalStore(BREADCRUMB_KEY);

  if (accountSelection && accountSelection.accountId) {
    return accountSelection.accountId || "UNSET";
  }
  return "UNSET";
}
console.log("Account Id? ", getAccountIdHeader());

const authHeaders = auth0.getAuthHeaders();

const authList = [
  authHeaders.Authorization.replace("Bearer ", ""),
  authHeaders["Auth-Token"],
  getAccountIdHeader()
];

let cable = ActionCable.createConsumer(
  AppConfig.actionCableUrl,
  authList.join("---")
);

window.cable = cable;

const hasSubscriptionOperation = ({ query: { definitions } }) =>
  definitions.some(
    ({ kind, operation }) =>
      kind === "OperationDefinition" && operation === "subscription"
  );

const authMiddlewareLink = setContext(() => {
  const headers = {};
  const middleAuthHeaders = auth0.getAuthHeaders();
  headers["Account-Id"] = getAccountIdHeader();
  Object.keys(middleAuthHeaders).forEach(key => {
    headers[key] = authHeaders[key];
  });

  return { headers };
});
const httpLinkWithSubscriptions = ApolloLink.split(
  hasSubscriptionOperation,
  new ActionCableLink({ cable }),
  httpLink
);

const link = authMiddlewareLink.concat(httpLinkWithSubscriptions);

config.setApolloClientOptions({
  link,
  cache,
  connectToDevTools: true, //Set to false to turn off in production
  //fragmentMatcher: brokerageEngineFragmentMatcher
});
const sagaMiddleware = createSagaMiddleware();

config.addStoreMiddleware(sagaMiddleware);
config.addAfterCreateStore(() => {
  sagaMiddleware.run(sagas);
});

// const fragment_matcher = new IntrospectionFragmentMatcher({..}
// const cache_config = { addTypename: true}
// const cache = new InMemoryCache(cache_config)
// const link = new HttpLink({ uri: 'http://localhost:4000' })
// const client = new ApolloClient({ link: link, cache: cache, fragmentMatcher: fragment_matcher })

// In app.js, we need to export the root component we want to mount as the
// starting point to our app.  We'll just export the `<Main>` component.
const history = createBrowserHistory();
// Create a new browser Apollo client
const client = browserClient();

// Create a new Redux store
const store = createNewStore(client);

function doRender() {
  // ReactDOM.hydrate(<Root />, document.getElementById("main"));
  ReactDOM.render(<Root />, document.getElementById("root"));
}
const Root = (() => {
  // Wrap the component hierarchy in <BrowserRouter>, so that our children
  // can respond to route changes
  const Chain = () => (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <Router history={history}>
          <Route component={Main} />
        </Router>
      </Provider>
    </ApolloProvider>
  );

  // React hot reloading -- only enabled in development.  This branch will
  // be shook from production, so we can run a `require` statement here
  // without fear that it'll inflate the bundle size
  // if (module.hot) {
  //   // <AppContainer> will respond to our Hot Module Reload (HMR) changes
  //   // back from WebPack, and handle re-rendering the chain as needed
  //   const AppContainer = require("react-hot-loader").AppContainer;
  //
  //   // Start our 'listener' at the root component, so that any changes that
  //   // occur in the hierarchy can be captured
  //   module.hot.accept("app", () => {
  //     // Refresh the entry point of our app, to get the changes.
  //
  //     // eslint-disable-next-line
  //     require("app").default;
  //
  //     // Re-render the hierarchy
  //     doRender();
  //   });
  //
  //   return () => (
  //     <AppContainer warnings={false}>
  //       <Chain />
  //     </AppContainer>
  //   );
  // }
  return Chain;
})();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

doRender();
