import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Route, Switch } from "react-router-dom";

import { graphql } from "react-apollo";
import { loader as graphqlLoader } from "graphql.macro";
import Loadable from "react-loadable";

import LoadingComponent from "src/components/main/LoadingComponent";
import NotificationSystem from "react-notification-system";
import Favicon from "react-favicon";
// NotFound 404 handler for unknown routes
import { Redirect } from "lib/routing";
import logoIcon from "static/Brokerage-Engine-icon.png";

const AsyncDashboards = Loadable({
  loader: () => import("components/dashboard"),
  loading: LoadingComponent
});

const AsyncUserProfile = Loadable({
  loader: () => import("components/user_profile"),
  loading: LoadingComponent
});

import { style } from "skin/src/variables/Variables";


const InitialLoginQL = graphqlLoader(
  "src/graphql/queries/InitialLogin.graphql"
);
import Subscriptions from "components/subscriptions";
import Callback from "components/login/Callback";
import Logout from "components/login/Logout";
import { MetaTitle, ErrorPanel, WhenNotFound } from "components/shared";

import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./Sidebar";


@graphql(InitialLoginQL)
export default class AccountMain extends Component {
  render() {
    const { data } = this.props;

    if (data.error) {
      if (
        data.error.networkError &&
        data.error.networkError.response &&
        data.error.networkError.response.status === 401
      ) {
        return (
          <div className="text-warning">
            <p>
              <FontAwesomeIcon icon="spinner" spin size="lg" fixedWidth />{" "}
              Requesting Authorization...
            </p>
          </div>
        );
      }
      return <ErrorPanel currentError="Unknown" data={data} />;
    }

    if (data.loading || data.error) {
      return (
        <div className="text-warning">
          <p>
            <FontAwesomeIcon icon="spinner" spin size="lg" fixedWidth />{" "}
            Loading...
          </p>
        </div>
      );
    }

    const { currentUser } = data.me;

    return (
      <div className="wrapper">
        <MetaTitle />
        <Subscriptions {...this.props} />

        <Favicon url={logoIcon} />
        <NotificationSystem ref="notificationSystem" style={style} />
        <Sidebar {...this.props} />
        <div id="main-panel" className="main-panel">
          <Header {...this.props} />
          <div id="main-error-area" />
          <Switch>
            <Route path="/dashboard" component={AsyncDashboards} />
            <Route path="/user_profile" component={AsyncUserProfile} />
            <Route path="/callback" render={props => <Callback {...props} />} />
            <Route path="/logout" render={props => <Logout {...props} />} />

            <Redirect from="/" to="/dashboard" />
            <Route component={WhenNotFound} />
          </Switch>

          <Footer />
        </div>
      </div>
    );
  }
}
