import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import classnames from "classnames";

import { Table, Alert, ButtonToolbar, Clearfix } from "react-bootstrap";
import { Button, Note } from "components/shared/";

const Notes = ({ notes }) => {
  if (!notes) {
    return null;
  }
  return (
    <div>
      {notes.map(note => (
        <Note key={note} note={note} />
      ))}
    </div>
  );
};
Notes.propTypes = {};
Notes.defaults = {};
export default Notes;
