import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal } from "react-bootstrap";
import {
  Page,
  Panel,
  PanelBody,
  PanelHeader,
  PanelFooter
} from "components/shared";

import { connect } from "react-redux";

import AuthenticationActions from "../../actions/AuthenticationActions";

const mapStateToProps = state => {
  return {};
};

class NotLoggedIn extends Component {
  login = () => {
    return new Promise((resolve, reject) => {
      this.props.serviceLogin(this.props.history, resolve, reject);
    });
  };

  logout = () => {
    return new Promise((resolve, reject) => {
      this.props.logOut();
    });
  };
  render() {
    return (
      <Modal show={true} bsSize="large">
        <Modal.Header>
          <Modal.Title>Please Sign In</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-primary text-center">
            Sign in with a valid account to get going.
          </p>

          <Button
            bsStyle="primary"
            className="center-block"
            onClick={this.login}
          >
            <FontAwesomeIcon icon={["far", "lock"]} /> Sign In
          </Button>
        </Modal.Body>
      </Modal>
    );
  }
}
export default connect(
  mapStateToProps,
  AuthenticationActions
)(NotLoggedIn);
