import React from "react";
import { connect } from "react-redux";

import loading from "./tail-spin.svg";
import AuthenticationActions from "../../actions/AuthenticationActions";

const mapStateToProps = state => ({
  ...state
});

class Callback extends React.Component {
  componentWillMount() {
    this.props.processLogin(this.props.location.hash, this.props.history);
  }
  render() {
    const style = {
      position: "absolute",
      display: "flex",
      justifyContent: "center",
      height: "100vh",
      width: "100vw",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: "white"
    };

    return (
      <div style={style}>
        <img src={loading} alt="loading" />
      </div>
    );
  }
}
export default connect(
  mapStateToProps,
  AuthenticationActions
)(Callback);
