import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ButtonToolbar } from "react-bootstrap";

import { Button, DestroyButton } from "components/shared/";

export default class NoteCard extends Component {
  render() {
    const {
      display,
      cardClass,
      title,
      category,
      createdAt,
      classes,
      styleOpts,
      contentClass,
      canEdit,
      canDestroy,
      destroyAction,
      id,
      content,
      children
    } = this.props;

    if (display !== undefined && display !== true) {
      return null;
    }

    return (
      <div
        className={"card " + cardClass}
        style={{ border: "solid 1px #FDD76B" }}
      >
        <div
          className="header"
          style={{
            backgroundColor: "#FDD76B",
            borderColor: "#FDD76B"
          }}
        >
          <h4 className="title">
            <FontAwesomeIcon
              icon={["far", "sticky-note"]}
              style={{ color: "#BD9420" }}
            />{" "}
            {title}
            <Button
              display={canEdit}
              to={`/notes/${id}/edit`}
              title={"Edit Note"}
              icon={"pencil-alt"}
              link
            />
            <DestroyButton
              title={"Delete Note"}
              display={canDestroy}
              link
              destroyAction={() => destroyAction(id)}
            />
          </h4>
          <span className="pull-right">
            <FontAwesomeIcon icon={["far", "calendar"]} /> Created: {createdAt}
          </span>
          <p className="category">{category}</p>
        </div>
        <div className={`content ${contentClass}`}>
          <div id={id} className={classes}>
            {content || children}
          </div>
        </div>
      </div>
    );
  }
}
