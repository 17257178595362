import React from "react";

import ConfirmButton from "./ConfirmButton";

const DisconnectButton = props => {
  const { destroyAction, disconnectAction, onClick, ...rest } = props;

  if (onClick) {
    console.warn(
      "Passing an onClick to the Disconnect button is not supported. Please change to disconnectAction"
    );
  }
  if (destroyAction) {
    console.warn(
      "Passing an destroyAction to the Disconnect button is not supported. Please change to disconnectAction"
    );
  }
  return (
    <ConfirmButton
      {...rest}
      link
      confirmAction={disconnectAction}
      initialIcon={"cut"}
      initialColor={"default"}
      initialText={""}
      actionIcon={"cut"}
      actionText={"Disconnect"}
      actionColor={"danger"}
    />
  );
};
export default DisconnectButton;
